import {
    Alert,
    AlertColor,
    Box,
    Button,
    Divider,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { ChangeEvent, FormEvent, SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AddOutlined, CancelOutlined, CheckCircleOutlined, CloseOutlined, SaveOutlined } from '@mui/icons-material';
import { Dealer } from '../../types/Dealer';
import { InfoMessage, InfoMessageProps } from '../../components/InfoMessage';
import ApiService from '../../ApiService';
import { TradeInMarket } from '../../types/TradeInMarket';
import FormSection from '../../components/FormSection';
import { TabsContainer } from '../../components/Tabs';
import TabPanel from '../../components/TabPanel';
import { AmcInstance } from '../../types/AmcInstance';
import DealerImportSites from './DealerImportSites';
import SasAccountComponent from './SasAccountComponent';
import DspEntityComponent from './DspEntityComponent';
import AgencySelector from '../../components/AgencySelector';
import DealerExportSites from './DealerExportSites';
import AdvertiserAdComponent from '../../components/AdvertiserAdComponent';
import { UserContext } from '../../App';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import AccessGuard, { hasAccess } from '../../components/AccessGuard';
import { USER_GROUP_ADMIN, USER_GROUP_AGENCY } from '../../types/User';
import DealerCreativeAssets from './DealerCreativeAssets';
import DealerDetailsEdit, { DealerUpdateCallback } from './DealerDetailsEdit';
import ActiveStatus from '../../components/ActiveStatus';
import { DspAdvertiser } from '../../types/DspEntity';
import { CDTextField } from '../../components/CDTextField';
import PolkSalesTab from './PolkSalesTab';
import AdTagBuilder from './AdTagBuilder';
import DealerGatewayAPI from './DealerGatewayAPI';

import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import Block from '../../components/Block';
import Column from '../../components/Column';

import HAT from './HAT';

const DealerEditPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { hash } = useLocation();
    const id = params.id ? +params.id : null;
    const [dealer, setDealer] = useState<Dealer>(new Dealer());
    const [markets, setMarkets] = useState<TradeInMarket[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [amcInstances, setAmcInstances] = useState<AmcInstance[]>([]);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const [selectedTab, setSelectedTab] = useState(0);
    const [duplicateDspAdvertiser, setDuplicateDspAdvertiser] = useState(false);
    const [showNewAdvertiserMessage, setShowNewAdvertiserMessage] = useState(false);
    const [hasDefaultDspEntity, setHasDefaultDspEntity] = useState(false);
    const [hasDefaultSasAccount, setHasDefaultSasAccount] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [duplicateId, setDuplicateId] = useState<number | undefined>();
    const [dspAdvertiser, setDspAdvertiser] = useState<DspAdvertiser | null>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const submitForm = (): void => {
        if (formRef.current) {
            formRef.current.requestSubmit();
        }
    };

    const { userContext } = useContext(UserContext);

    const TABS = {
        SETTINGS: 0,
        TRADE_IN_DCO: 1,
        INTEGRATION_MAP: 2,
        IMPORT_SITES: 3,
        EXPORT_SITES: 4,
        CREATIVE: 5,
        HAT: 6,
        POLK_SALES: 7,
        CREATIVE_ASSETS: 8,
        GATEWAY_API: 9,
    };

    const showPageActions: boolean | undefined = useMemo(() => {
        if (selectedTab === TABS.POLK_SALES) {
            return false;
        }

        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    useEffect(() => {
        if (id) {
            ApiService.getDealerOverview(id)
                .then((response) => {
                    const dealerOverview = response.data;
                    const dealer = dealerOverview.dealer;
                    setAmcInstances(dealerOverview.amcInstances);
                    setMarkets(dealerOverview.markets);
                    setDealer(dealer);
                    setHasDefaultDspEntity(dealer.dspEntityId !== null);
                    setHasDefaultSasAccount(dealer.sasAccountId !== null);
                    setLoaded(true);
                    if (userContext.isAdmin()) {
                        lookupDspAdvertiser(dealer.dspEntityId, dealer.dspAdvertiser);
                    }

                    ApiService.isDuplicate(dealer)
                        .then((response) => {
                            let duplicates = response.data;
                            let hasDuplicate = duplicates.length > 0;
                            setDuplicate(hasDuplicate);
                            if (hasDuplicate) {
                                showInfoMessage('error', 'Duplicate Advertiser name.');
                            }
                        })
                        .catch(() => {});
                })
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        navigate('/notFound');
                    }
                });
        } else {
            ApiService.getDefaultDealer()
                .then((response) => {
                    setDealer({
                        ...response.data,
                        agencyId: userContext.agencyId,
                    });
                    setHasDefaultDspEntity(response.data.dspEntityId !== null);
                    setHasDefaultSasAccount(response.data.sasAccountId !== null);
                    setLoaded(true);
                })
                .catch(() => {});
        }
    }, [navigate, id, userContext, userContext.agencyId]);

    useEffect(() => {
        if (hash) {
            switch (hash) {
                case '#polkSales':
                    setSelectedTab(TABS.POLK_SALES);
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash]);

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    function modifyDealer(update: Partial<Dealer>) {
        setDealer({ ...dealer, ...update, modified: true });
    }

    function handleToggleStatus() {
        const updatedActiveStatus = !dealer.activeStatus;
        modifyDealer({ activeStatus: updatedActiveStatus });
    }

    const handleDealerDetailsUpdate: DealerUpdateCallback = (name, update) => {
        modifyDealer(update);

        if (name === 'dspEntityId') {
            ApiService.getDealersByDspAdvertiserId(String(update.dspEntityId)).then((response) => {
                let dealers = response.data;
                if (dealer.id) {
                    dealers = dealers.filter((d) => {
                        return d.id !== dealer.id;
                    });
                }
                if (dealers.length > 0) {
                    showInfoMessage(
                        'error',
                        'Duplicate ADSP Advertiser : ' + dealers.map((d) => d.dealerName).join(', ')
                    );
                    setDuplicateDspAdvertiser(true);
                } else {
                    setDuplicateDspAdvertiser(false);
                }
            });
        }
    };

    function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
        const { target } = event;
        const field = target.name;

        if (
            field === 'tradeInEnrolled' ||
            field.startsWith('dspReport') ||
            field.startsWith('tfTokenIsActive') ||
            field === 'gatewayEnabled'
        ) {
            modifyDealer({ [field]: target.checked ?? false });
        } else if (field === 'tradeInMarketId') {
            modifyDealer({ tradeInMarket: { id: +target.value } });
        } else {
            let value: string = target.value;

            switch (field) {
                case 'dspAdvertiser':
                case 'sizmekAdvertiserId':
                case 'ftAdvertiserId':
                    value = value.replace(/\s/g, ''); // Remove spaces
                    break;
            }

            modifyDealer({ [field]: value });
        }
    }

    function lookupDspAdvertiser(dspEntityId: number | undefined, dspAdvertiser: string) {
        if (dspEntityId) {
            ApiService.getDspAdvertiser(dspEntityId, dspAdvertiser).then((response) => {
                setDspAdvertiser(response.data);
            });
        }
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!dealer.agencyId) {
            return;
        }
        if (dealer.amcInstanceId && +dealer.amcInstanceId < 1) {
            dealer.amcInstanceId = undefined;
        }
        let isNew = !dealer.id;
        setDuplicate(false);

        ApiService.updateDealer(dealer)
            .then((response) => {
                if (isNew && userContext.isAgency()) {
                    setShowNewAdvertiserMessage(true);
                } else {
                    showInfoMessage('success', 'Advertiser has been updated.');
                }
                setDealer(response.data);
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    let duplicates = error.response.data;
                    setDuplicate(true);
                    // Only show this to Admins, Agencies get a different message block below.
                    if (!userContext.isAgency()) {
                        showInfoMessage('error', 'Duplicate Advertiser name.');
                    }
                    if (duplicates.length > 0) {
                        setDuplicateId(duplicates[0].id);
                    }
                } else {
                    showInfoMessage('error', error.response.data);
                }
            });
    }

    function selectTab(_event: SyntheticEvent, value: any) {
        setSelectedTab(value);
    }

    if (!loaded) {
        return <></>;
    }

    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <AppBreadcrumbs
                        items={[{ label: 'Advertisers', href: '/advertisers' }, { label: dealer.dealerName || '...' }]}
                    />
                </Column>
            </Block>

            <TabsContainer>
                <Tabs value={selectedTab} onChange={selectTab}>
                    {hasAccess(userContext, `${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`) && (
                        <Tab value={TABS.SETTINGS} label="Settings" style={{ textTransform: 'none' }} />
                    )}
                    {hasAccess(userContext, USER_GROUP_ADMIN) && (
                        <Tab value={TABS.CREATIVE_ASSETS} label="Creative Assets" />
                    )}
                    {hasAccess(userContext, USER_GROUP_ADMIN) && (!dealer.sasAccountId || !dealer.dspEntityId) && (
                        <Tab
                            value={TABS.INTEGRATION_MAP}
                            label={<Alert severity="error">Integration Map</Alert>}
                            style={{ textTransform: 'none' }}
                        />
                    )}
                    {hasAccess(userContext, USER_GROUP_ADMIN) && dealer.sasAccountId && dealer.dspEntityId && (
                        <Tab
                            value={TABS.INTEGRATION_MAP}
                            label={
                                !dealer.agencyId ? <Alert severity="error">Integration Map</Alert> : 'Integration Map'
                            }
                            style={{ textTransform: 'none' }}
                        />
                    )}
                    {hasAccess(userContext, USER_GROUP_ADMIN) && <Tab value={TABS.IMPORT_SITES} label="Import Sites" />}
                    {hasAccess(userContext, USER_GROUP_ADMIN) && <Tab value={TABS.EXPORT_SITES} label="Export Sites" />}
                    {hasAccess(userContext, USER_GROUP_ADMIN) && (
                        <Tab value={TABS.TRADE_IN_DCO} label="Trade-In DCO" style={{ textTransform: 'none' }} />
                    )}
                    {hasAccess(userContext, USER_GROUP_ADMIN) && <Tab value={TABS.CREATIVE} label="Preview Creative" />}
                    {dealer?.agency?.tfUserIsActive &&
                        hasAccess(userContext, `${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`) && (
                            <Tab value={TABS.HAT} label="HAT" />
                        )}
                    {hasAccess(userContext, `${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`) && (
                        <Tab value={TABS.POLK_SALES} label="Polk Sales" />
                    )}
                </Tabs>
            </TabsContainer>

            <form ref={formRef} onSubmit={handleSubmit}>
                <Column>
                    <Block disableGutters={selectedTab === TABS.HAT}>
                        <AccessGuard accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}>
                            <TabPanel index={TABS.SETTINGS} value={selectedTab}>
                                <FormControlLabel
                                    sx={{ marginBottom: '8px' }}
                                    control={
                                        <>
                                            <Tooltip title="Set the Active Status for this Advertiser.  Inactivating hides this Advertiser from selection when creating Campaigns and other areas.">
                                                <Switch
                                                    checked={dealer.activeStatus}
                                                    onChange={handleToggleStatus}
                                                    name={'activeStatus'}
                                                />
                                            </Tooltip>
                                        </>
                                    }
                                    label={<ActiveStatus activeStatus={dealer.activeStatus} />}
                                />

                                <Box sx={{ maxWidth: '800px' }}>
                                    <DealerDetailsEdit
                                        dealer={dealer}
                                        hasDefaultSasAccount={hasDefaultSasAccount}
                                        hasDefaultDspEntity={hasDefaultDspEntity}
                                        duplicateId={duplicate ? duplicateId : undefined}
                                        onDealerUpdate={handleDealerDetailsUpdate}
                                        shrinkTextFieldLabels={true}
                                    />
                                </Box>
                            </TabPanel>
                        </AccessGuard>

                        <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                            <>
                                <TabPanel index={TABS.TRADE_IN_DCO} value={selectedTab}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={dealer.tradeInEnrolled}
                                                        name="tradeInEnrolled"
                                                        onChange={handleInputChange}
                                                    />
                                                }
                                                label="Enrolled"
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                label="Select a Market"
                                                name="tradeInMarketId"
                                                select
                                                value={dealer.tradeInMarket?.id + ''}
                                                disabled={!dealer.tradeInEnrolled}
                                                onChange={handleInputChange}
                                                SelectProps={{ native: false }}
                                            >
                                                {markets.map((market) => {
                                                    return (
                                                        <MenuItem key={market.id + ''} value={market.id + ''}>
                                                            {market.marketName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={4} />

                                        <Grid item xs={4}>
                                            <Button
                                                component="button"
                                                onClick={() => navigate(`/tradeInOffers/${dealer.id}`)}
                                                disabled={
                                                    dealer.modified || !dealer.tradeInEnrolled || !dealer.tradeInMarket
                                                }
                                            >
                                                Offers
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel index={TABS.INTEGRATION_MAP} value={selectedTab}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={4}>
                                            <AgencySelector
                                                label="Agency"
                                                required={true}
                                                agencyId={dealer.agencyId}
                                                agencySelected={(agencyId) => {
                                                    modifyDealer({
                                                        agencyId: agencyId,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={8} />

                                        <Grid item xs={4}>
                                            <SasAccountComponent
                                                sasAccountId={dealer.sasAccountId}
                                                onSelectSasAccount={(accountId: number) => {
                                                    if (accountId !== dealer.sasAccountId) {
                                                        modifyDealer({
                                                            sasAccountId: accountId,
                                                        });
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={8} />

                                        <Grid item xs={4}>
                                            <DspEntityComponent
                                                dspEntityId={dealer.dspEntityId}
                                                onSelectDspEntity={(dspEntityId: number) => {
                                                    if (dspEntityId !== dealer.dspEntityId) {
                                                        modifyDealer({
                                                            dspEntityId: dspEntityId,
                                                        });
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={8} />

                                        <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                                            <>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        name="dspAdvertiser"
                                                        label="ADSP Advertiser ID"
                                                        size="small"
                                                        fullWidth={true}
                                                        error={duplicateDspAdvertiser}
                                                        variant="outlined"
                                                        onBlur={() =>
                                                            lookupDspAdvertiser(
                                                                dealer.dspEntityId,
                                                                dealer.dspAdvertiser
                                                            )
                                                        }
                                                        onChange={handleInputChange}
                                                        value={dealer.dspAdvertiser}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <CDTextField
                                                        value={
                                                            dspAdvertiser ? dspAdvertiser.name : 'Invalid Advertiser ID'
                                                        }
                                                        disabled={true}
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    {dspAdvertiser &&
                                                                    dspAdvertiser?.name === dealer.dealerName ? (
                                                                        <CheckCircleOutlined color="success" />
                                                                    ) : (
                                                                        <span
                                                                            title={
                                                                                dspAdvertiser &&
                                                                                dspAdvertiser?.name !==
                                                                                    dealer.dealerName
                                                                                    ? 'Advertiser names do not match'
                                                                                    : ''
                                                                            }
                                                                        >
                                                                            <CancelOutlined color="error" />
                                                                        </span>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                        name="sizmekAdvertiserId"
                                                        label="AAS (Sizmek) ID"
                                                        size="small"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        onChange={handleInputChange}
                                                        value={dealer.sizmekAdvertiserId}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>

                                                <Grid item xs={8} />

                                                <Grid item xs={4}>
                                                    <TextField
                                                        name="ftAdvertiserId"
                                                        label="Flashtalking Advertiser ID"
                                                        size="small"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        onChange={handleInputChange}
                                                        value={dealer.ftAdvertiserId}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>

                                                <Grid item xs={8} />

                                                <Grid item xs={4}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        label="AMC Instance"
                                                        name="amcInstanceId"
                                                        select
                                                        value={dealer.amcInstanceId ? dealer.amcInstanceId : '-1'}
                                                        disabled={false}
                                                        onChange={handleInputChange}
                                                        SelectProps={{ native: false }}
                                                    >
                                                        <MenuItem key={'-1'} value={'-1'}>
                                                            None
                                                        </MenuItem>

                                                        {amcInstances.map((amc) => {
                                                            return (
                                                                <MenuItem key={amc.id + ''} value={amc.id + ''}>
                                                                    {amc.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </TextField>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <AdTagBuilder
                                                        dealer={dealer}
                                                        showInfoMessage={showInfoMessage}
                                                        handleInputChange={handleInputChange}
                                                    />
                                                </Grid>

                                                <Grid item xs={8} sx={{ mt: 2 }}>
                                                    <Grid item>
                                                        <FormSection>
                                                            <Typography variant="h6">DSP API Reports</Typography>

                                                            <Grid container spacing={3}>
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={dealer.dspReportImpressions}
                                                                                name="dspReportImpressions"
                                                                                onChange={handleInputChange}
                                                                                disabled={
                                                                                    !dspAdvertiser ||
                                                                                    !dspAdvertiser.advertiserId
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Impressions"
                                                                    />
                                                                </Grid>

                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={dealer.dspReportSummaryGeo}
                                                                                name="dspReportSummaryGeo"
                                                                                onChange={handleInputChange}
                                                                                disabled={
                                                                                    !dspAdvertiser ||
                                                                                    !dspAdvertiser.advertiserId
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Summary GEO"
                                                                    />
                                                                </Grid>

                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={
                                                                                    dealer.dspReportSummaryAppBySpend
                                                                                }
                                                                                name="dspReportSummaryAppBySpend"
                                                                                onChange={handleInputChange}
                                                                                disabled={
                                                                                    !dspAdvertiser ||
                                                                                    !dspAdvertiser.advertiserId
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Summary App by Spend"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </FormSection>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <Grid item>
                                                        <FormSection>
                                                            <DealerGatewayAPI
                                                                dealer={dealer}
                                                                onDealerUpdate={handleDealerDetailsUpdate}
                                                            />
                                                        </FormSection>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} />
                                            </>
                                        </AccessGuard>
                                    </Grid>
                                </TabPanel>

                                <TabPanel index={TABS.IMPORT_SITES} value={selectedTab}>
                                    <DealerImportSites dealer={dealer} />
                                </TabPanel>

                                <TabPanel index={TABS.EXPORT_SITES} value={selectedTab}>
                                    <DealerExportSites dealer={dealer} onChange={(dealer) => modifyDealer(dealer)} />
                                </TabPanel>
                                <TabPanel index={TABS.CREATIVE} value={selectedTab}>
                                    <AdvertiserAdComponent dealerId={dealer.id ? dealer.id : 0} />
                                </TabPanel>

                                <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                                    <>
                                        <TabPanel index={TABS.CREATIVE_ASSETS} value={selectedTab}>
                                            <Box sx={{ maxWidth: '800px' }}>
                                                <DealerCreativeAssets
                                                    dealer={dealer}
                                                    onDealerUpdate={handleDealerDetailsUpdate}
                                                    shrinkTextFieldLabels={true}
                                                />
                                            </Box>
                                        </TabPanel>
                                    </>
                                </AccessGuard>
                            </>
                        </AccessGuard>

                        <AccessGuard accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}>
                            <>
                                {dealer?.agency?.tfUserIsActive && (
                                    <TabPanel index={TABS.HAT} value={selectedTab} sx={{ p: 0 }}>
                                        <HAT
                                            dealer={dealer}
                                            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                handleInputChange(event);

                                                setTimeout(() => {
                                                    submitForm();
                                                }, 500);
                                            }}
                                        />
                                    </TabPanel>
                                )}
                            </>
                        </AccessGuard>

                        <AccessGuard accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}>
                            <>
                                <TabPanel index={TABS.POLK_SALES} value={selectedTab}>
                                    <PolkSalesTab dealer={dealer} />
                                </TabPanel>
                            </>
                        </AccessGuard>
                    </Block>

                    {showPageActions === true && (
                        <>
                            <Divider />

                            <Block py={0}>
                                {showNewAdvertiserMessage && (
                                    <>
                                        {' '}
                                        <Alert style={{ marginBottom: '15px' }} severity="success">
                                            Your new advertiser is ready. Next, Launch a New Campaign to get ads
                                            running!{' '}
                                        </Alert>
                                        <Link
                                            style={{ paddingTop: '200px' }}
                                            to="/campaign/create"
                                            state={{ dealerId: dealer.id }}
                                        >
                                            <Button variant="contained" color="primary" startIcon={<AddOutlined />}>
                                                New Campaign
                                            </Button>
                                        </Link>
                                    </>
                                )}
                                {!showNewAdvertiserMessage && (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Stack direction="row" spacing={2}>
                                                <Button
                                                    disabled={
                                                        duplicateDspAdvertiser ||
                                                        !dealer.modified ||
                                                        !dealer.sasAccountId ||
                                                        !dealer.agencyId
                                                    }
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={<SaveOutlined />}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => navigate('/advertisers')}
                                                    color="error"
                                                    startIcon={<CloseOutlined />}
                                                >
                                                    Cancel
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                )}
                            </Block>
                        </>
                    )}
                </Column>
            </form>

            <InfoMessage {...infoMessage} />
        </Column>
    );
};

export default DealerEditPage;
