import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
    Autocomplete,
    AutocompleteProps,
    Checkbox,
    Chip,
    Paper,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material';

import { Agency } from '../types/Agency';
import ApiService from '../ApiService';

// interface AutocompleteOption {
//     id: number;
//     name: string;
//     label: string;
//     value: string;
// }

const icon = <CheckBoxOutlineBlankIcon color="action" />;
const checkedIcon = <CheckBoxIcon color="primary" />;

export type AgencyAutocompleteProps = {
    onFetch?: (agencies: Agency[]) => void;
    onFetching?: () => void;

    value?: number[];
    error?: boolean;
    label?: string;
    required?: boolean;
    variant?: TextFieldProps['variant'];
    TextFieldProps?: TextFieldProps;
    defaultValueSelected?: boolean;
} & Omit<AutocompleteProps<Agency, true, false, false>, 'renderInput' | 'options' | 'value'>;

export default function AgencyAutocomplete(props: AgencyAutocompleteProps) {
    const { onFetch, onFetching, value = [], defaultValueSelected = false, ...autocompleteProps } = props;
    const { onChange } = props;

    const isInitialized = useRef<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [agencies, setAgencies] = useState<Agency[]>([]);
    const [valueOptions, setValueOptions] = useState<Agency[]>([]);

    const fetchAgencies = useCallback(() => {
        if (onFetching) {
            onFetching();
        }

        ApiService.getAgencies()
            .then((response) => {
                if (onFetch) {
                    onFetch(response.data);
                }
                setAgencies(response.data.filter((agency: { activeStatus: boolean }) => agency.activeStatus));
            })
            .catch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchAgencies();
    }, [fetchAgencies]);

    useEffect((): void => {
        if (isInitialized.current === false && value.length === 0) {
            if (defaultValueSelected && agencies.length > 0) {
                isInitialized.current = true;

                if (onChange) {
                    const event = new Event('selectOption') as unknown as SyntheticEvent<Element, Event>;
                    onChange(event, [agencies[0]], 'selectOption');
                }
            }
        } else {
            let defaultOptions: Agency[] = agencies.filter((agency: Agency) => value.includes(agency.id as number));
            setValueOptions(defaultOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agencies, value, defaultValueSelected]);

    return (
        <Autocomplete
            multiple
            size="small"
            fullWidth={false}
            disableCloseOnSelect={true}
            value={valueOptions}
            options={agencies}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderTags={(tagValue, getTagProps) => {
                if (isFocused) {
                    return null;
                }

                const tagValueCount: number = tagValue.length;

                const getChipLabel = (tagValue: any): string => {
                    return `${tagValueCount} Selected`;
                };

                if (tagValueCount > 1) {
                    const index = 0;
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                        <Chip
                            key={key}
                            label={getChipLabel(tagValue)}
                            size="small"
                            {...tagProps}
                            onDelete={() => {
                                const event = new Event('clear') as unknown as SyntheticEvent<Element, Event>;

                                if (props.onChange) {
                                    props.onChange(event, [], 'clear');
                                }
                            }}
                        />
                    );
                }

                return (
                    <Typography
                        sx={{
                            px: (theme) => theme.spacing(1),
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {tagValueCount > 0 ? tagValue[0].name : ''}
                    </Typography>
                );
            }}
            renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                    <li key={key} {...optionProps}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.name}
                    </li>
                );
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            renderInput={(inputProps) => (
                <TextField
                    error={props.error}
                    label={props.label ?? 'Select an Agency'}
                    variant={props.variant ?? 'outlined'}
                    required={props.required !== undefined ? props.required : !props.disabled}
                    {...inputProps}
                    {...props.TextFieldProps}
                />
            )}
            PaperComponent={(paperComponentProps) => (
                <Paper
                    {...paperComponentProps}
                    sx={{
                        boxShadow: 8,
                        borderRadius: '8px',
                    }}
                />
            )}
            sx={{
                '& .MuiAutocomplete-popupIndicator': {
                    color: 'action.active',
                },
                '& .MuiAutocomplete-inputRoot': {
                    height: '2.5rem',
                    overflow: 'hidden',
                },
            }}
            {...autocompleteProps}
        />
    );
}
