import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Alert, AlertTitle, Divider, FormControlLabel, Grid, Stack, Switch, Tooltip } from '@mui/material';
import { Dealer } from '../../types/Dealer';
import { TrackingStatus, Tracking } from '../../types/HAT/Tracking';
import { USER_GROUP_ADMIN } from '../../types/User';
import ApiService from '../../ApiService';
import AccessGuard from '../../components/AccessGuard';
import Block from '../../components/Block';
import Column from '../../components/Column';
import ContentCard from './partials/ContentCard';
import CreativePixelTabsCard from './partials/HAT/CreativePixelTabsCard';
import TrackingStatusChip from './partials/HAT/TrackingStatusChip';

export default function HAT(props: { dealer: Dealer; handleChange: (event: ChangeEvent<HTMLInputElement>) => void }) {
    const { dealer, handleChange } = props;

    const [tracking, setTracking] = useState<Tracking | null>(null);

    const fetchTracking = useCallback((advertiserId: number) => {
        ApiService.getHatTracking(advertiserId).then((response) => {
            setTracking(response.data);
        });
    }, []);

    useEffect(() => {
        if (dealer?.id) {
            fetchTracking(dealer.id);
        }
    }, [fetchTracking]);

    const snippetData = {
        title: getTitleElement('Tracking Code'),
        mainContent: `<!-- HAT Start --> \n ${dealer.tfSnippet}\n<!-- HAT End -->`,
        description:
            tracking && tracking.status === TrackingStatus.DEAD ? (
                <Alert severity="error">Ensure your Tracking Code is installed correctly.</Alert>
            ) : (
                "Below is the Website Tag for this advertiser. Copy and paste it in the code of every page of your website, immediately after the 'head' element. This can be done through a Tag Manager like GTM. Don’t add more than one tag to each page."
            ),
    };

    const apiKeyData = {
        title: 'API Token',
        mainContent: dealer.tfApiToken,
        description: 'Below is the API Token for this advertiser. Use it when sending events programmatically.',
    };

    function getTitleElement(title: string) {
        return (
            <>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <span>{title}</span>
                    <span style={{ flex: 1 }} />
                    <TrackingStatusChip tracking={tracking} />
                </Stack>
            </>
        );
    }

    return (
        <>
            <Block>
                <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <>
                                    <Tooltip title="HAT Integrations">
                                        <Switch
                                            checked={dealer.tfTokenIsActive}
                                            onChange={handleChange}
                                            name={'tfTokenIsActive'}
                                            disabled={dealer.agency?.tfUserIsActive ? false : true}
                                        />
                                    </Tooltip>
                                </>
                            }
                            label="HAT Integrations"
                        />
                    </Grid>
                </AccessGuard>
            </Block>

            <Divider />

            <Block py={3}>
                <Column gap={4}>
                    {!dealer.tfTokenIsActive && (
                        <Alert severity="info">
                            <AlertTitle>HAT Integrations Not Enabled</AlertTitle>
                            HAT Integration is currently disabled. To view the tracking code, API token, and creative
                            pixels, please enable the HAT Integration.
                        </Alert>
                    )}

                    {dealer.tfTokenIsActive && dealer.tfApiToken && (
                        <>
                            <ContentCard cardData={snippetData} />

                            <ContentCard cardData={apiKeyData} />

                            <CreativePixelTabsCard advertiser={dealer} />
                        </>
                    )}
                </Column>
            </Block>
        </>
    );
}
