import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import { AgencyContext } from '../App';
import { AttributionStatus, PolkDealer, PolkDealerDealer, PolkSalesAnalysis, PolkSalesWorkflow } from '../types/Polk';
import { AttributionPerformance, Performance } from '../types/CampaignPerformance';
import { ReportingCriteria } from '../types/Analytics/Reporting/ReportingCriteria';
import { getUniquePerformanceStrategyTypes } from './useCampaignPerformance';
import { useTabs } from './Tabs';
import ApiService from '../ApiService';
import Utils from '../components/Utils';

import { Metric } from '../components/Metrics/MetricCard';
import { TABS } from '../pages/analytics/partials/Reporting/Tabs/ReportingMediaTabs';

export class AttributionMetrics {
    averageCPM: Metric | null | undefined = null;
    averageCTR: Metric | null | undefined = null;
    averageViewabilityRate: Metric | null | undefined = null;

    totalSales: Metric | null | undefined = null;
    attributedSales: Metric | null | undefined = null;
    totalSpend: Metric | null | undefined = null;
    costPerSold: Metric | null | undefined = null;
    totalImpressions: Metric | null | undefined = null;
    totalClickthroughs: Metric | null | undefined = null;
    totalDailyReach: Metric | null | undefined = null;

    totalMarketShares: Metric | null | undefined = null;
    totalAdShares: Metric | null | undefined = null;

    videoCompleteRate: Metric | null | undefined = null;
    costPerCompletedVideo: Metric | null | undefined = null;
    timeSpent: Metric | null | undefined = null;
}

export class ReportingDashboardPageContextValue {
    mediaTabs: any = null;
    performanceTabs: any = null;

    defaultMinDate: Date = new Date();
    defaultMaxDate: Date = new Date();
    defaultStartDate: Date = new Date();
    defaultEndDate: Date = new Date();

    isReportingCriteriaSetup: boolean = false;
    setIsReportingCriteriaSetup: Function = (isReportingCriteriaSetup: boolean) => {};
    reportingCriteria: ReportingCriteria | null = null;
    setReportingCriteria: Function = (reportingCriteria: ReportingCriteria) => {};

    attributionStatus: AttributionStatus = {
        salesWorkflows: [],
        inProgress: true,
    };
    setAttributionStatus: Function = (attributionStatus: AttributionStatus) => {};
    // fetchAttributionStatus: Function = () => {};
    isFetchingAttributionStatus: boolean = false;

    attributionStatusUpdateDate: Date | null = null;
    setAttributionStatusUpdateDate: Function = (attributionStatusUpdateDate: Date | null) => {};
    attributionStatusUpdateDateFormatted: string | undefined = undefined;

    advertiserSalesUpdateDate: Date | null = null;
    setAdvertiserSalesUpdateDate: Function = (advertiserSalesUpdateDate: Date | null) => {};
    advertiserSalesUpdateDateFormatted: string | undefined = undefined;

    polkDealerDealers: PolkDealerDealer[] = [];
    setPolkDealerDealers: Function = (polkDealerDealers: PolkDealerDealer[]) => {};
    isAdvertiserPolkDealer: Function = (polkDealer: PolkDealer): boolean => false;
    isCompetitorPolkDealer: Function = (polkDealer: PolkDealer): boolean => false;
    getAdvertiserPolkDealerDealers: Function = (): PolkDealerDealer[] => [];
    getCompetitorPolkDealerDealers: Function = (): PolkDealerDealer[] => [];

    polkSalesAnalysis: PolkSalesAnalysis[] = [];
    setPolkSalesAnalysis: Function = (polkSalesAnalysis: PolkSalesAnalysis[]) => {};

    polkSalesWorkflow: PolkSalesWorkflow | null = null;
    setPolkSalesWorkflow: Function = (polkSalesWorkflow: PolkSalesWorkflow | null) => {};
    polkOpportunitiesWorkflow: PolkSalesWorkflow | null = null;
    setPolkOpportunitiesWorkflow: Function = (polkOpportunitiesWorkflow: PolkSalesWorkflow | null) => {};

    performances: Performance[] = [];
    setPerformances: Function = (performances: Performance[]) => {};
    fetchPerformances: Function = () => {};
    isFetchingPerformances: boolean = false;
    setIsFetchingPerformances: Function = (isFetchingPerformances: boolean) => {};

    activeStrategyTypes: Record<string, string> = {};
    setActiveStrategyTypes: Function = (activeStrategyTypes: Record<string, string>) => {};
    isActiveStrategyType: Function = (strategyType: string) => false;

    activeMediaTypes: Record<string, string> = {};
    setActiveMediaTypes: Function = (activeMediaTypes: Record<string, string>) => {};
    isActiveMediaType: Function = (mediaType: string) => false;
    fetchActiveMediaTypes: Function = () => {};
    isFetchingActiveMediaTypes: boolean = false;
    setIsFetchingActiveMediaTypes: Function = (isFetchingActiveMediaTypes: boolean) => {};

    hasDisplayMediaType: boolean = false;
    hasVideoMediaType: boolean = false;
    hasAudioMediaType: boolean = false;

    attributionPerformances: AttributionPerformance[] = [];
    setAttributionPerformances: Function = (attributionPerformances: AttributionPerformance[]) => {};
    fetchAttributionPerformances: Function = () => {};
    isFetchingAttributionPerformances: boolean = false;
    setIsFetchingAttributionPerformances: Function = (isFetchingAttributionPerformances: boolean) => {};

    getPerformances: Function = (mediaType?: string) => {};
    getPerformanceMediaTypes: Function = () => {};
    getPerformanceLabel: Function = (performance: Performance) => {};

    attributionMetrics: AttributionMetrics = new AttributionMetrics();
    setAttributionMetrics: Function = (attributionMetrics: AttributionMetrics) => {};
}

export const ReportingDashboardPageContext = createContext<ReportingDashboardPageContextValue>(
    new ReportingDashboardPageContextValue()
);

export function useReportingDashboardPageContext() {
    return useContext(ReportingDashboardPageContext);
}

export type ReportingDashboardPageProps = {};

export default function useReportingDashboardPage() {
    const agency = useContext(AgencyContext);
    const agencyId: number = agency?.id as number;

    let defaultMinDate = new Date(2023, 0, 1);
    let defaultMaxDate: Date = moment().subtract(1, 'days').startOf('day').toDate();
    let defaultStartDate: Date = moment().subtract(30, 'days').startOf('day').toDate();
    let defaultEndDate: Date = defaultMaxDate;

    const [isReportingCriteriaSetup, setIsReportingCriteriaSetup] = useState<boolean>(false);

    const [reportingCriteria, setReportingCriteria] = useState<ReportingCriteria>(() => {
        const savedReportingCriteria: string | null = localStorage.getItem('reporting.reportingCriteria');

        if (savedReportingCriteria) {
            return JSON.parse(savedReportingCriteria);
        }

        return {
            agencyIds: agencyId > 0 ? [agencyId] : [],
            dealerIds: [],
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            mediaType: '',
            strategyType: '',
        };
    });

    useEffect((): void => {
        localStorage.setItem('reporting.reportingCriteria', JSON.stringify(reportingCriteria));
    }, [reportingCriteria]);

    const mediaTabs = useTabs();
    const performanceTabs = useTabs();

    const [attributionStatus, setAttributionStatus] = useState<AttributionStatus>({
        salesWorkflows: [],
        inProgress: true,
    });
    const [isFetchingAttributionStatus, setIsFetchingAttributionStatus] = useState<boolean>(false);

    const [attributionStatusUpdateDate, setAttributionStatusUpdateDate] = useState<Date | null>(null);

    const attributionStatusUpdateDateFormatted: string | undefined = useMemo(() => {
        if (attributionStatusUpdateDate) {
            return moment(attributionStatusUpdateDate).format('M/D/YYYY [at] h:mm a');
        }
    }, [attributionStatusUpdateDate]);

    const [advertiserSalesUpdateDate, setAdvertiserSalesUpdateDate] = useState<Date | null>(null);
    const advertiserSalesUpdateDateFormatted = useMemo((): string | undefined => {
        if (advertiserSalesUpdateDate) {
            return moment(advertiserSalesUpdateDate).format('M/D/YYYY [at] h:mm a');
        }
    }, [advertiserSalesUpdateDate]);

    const [polkDealerDealers, setPolkDealerDealers] = useState<PolkDealerDealer[]>([]);
    const [polkSalesAnalysis, setPolkSalesAnalysis] = useState<PolkSalesAnalysis[]>([]);

    const [polkSalesWorkflow, setPolkSalesWorkflow] = useState<PolkSalesWorkflow | null>(null);
    const [polkOpportunitiesWorkflow, setPolkOpportunitiesWorkflow] = useState<PolkSalesWorkflow | null>(null);

    const [performances, setPerformances] = useState<Performance[]>([]);
    const [isFetchingPerformances, setIsFetchingPerformances] = useState<boolean>(false);

    const [activeStrategyTypes, setActiveStrategyTypes] = useState<Record<string, string>>({});
    const isActiveStrategyType = (strategyType: string): boolean => {
        return strategyType in activeStrategyTypes;
    };

    const [activeMediaTypes, setActiveMediaTypes] = useState<Record<string, string>>({});
    const [isFetchingActiveMediaTypes, setIsFetchingActiveMediaTypes] = useState<boolean>(false);
    const isActiveMediaType = (mediaType: string): boolean => {
        return mediaType in activeMediaTypes;
    };

    const [hasDisplayMediaType, setHasDisplayMediaType] = useState<boolean>(false);
    const [hasVideoMediaType, setHasVideoMediaType] = useState<boolean>(false);
    const [hasAudioMediaType, setHasAudioMediaType] = useState<boolean>(false);

    useEffect(() => {
        // Only check this logic if all media as it
        // queries all the media types.
        if (mediaTabs.activeTab === TABS.ALL) {
            setHasDisplayMediaType(
                performances &&
                    performances.findIndex((performance: Performance) => performance.mediaType === 'Display') > -1
            );
            setHasVideoMediaType(
                performances &&
                    performances.findIndex((performance: Performance) => performance.mediaType === 'Video') > -1
            );
            setHasAudioMediaType(
                performances &&
                    performances.findIndex((performance: Performance) => performance.mediaType === 'Audio') > -1
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances, mediaTabs.activeTab]);

    useEffect(() => {
        if (reportingCriteria.strategyType === '') {
            let newActiveStrategyTypes: Record<string, string> = {};

            getUniquePerformanceStrategyTypes(performances).forEach((strategyType: string) => {
                newActiveStrategyTypes[strategyType] = strategyType.replace(/([a-z])([A-Z])/g, '$1 $2');
            });

            setActiveStrategyTypes(newActiveStrategyTypes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances, reportingCriteria.strategyType]);

    const [attributionPerformances, setAttributionPerformances] = useState<AttributionPerformance[]>([]);
    const [isFetchingAttributionPerformances, setIsFetchingAttributionPerformances] = useState<boolean>(false);

    const getPerformances = (mediaType?: string): Performance[] => {
        if (performances && performances.length) {
            return performances.filter((p: Performance) => p.mediaType === mediaType);
        }

        return [];
    };

    const getPerformanceMediaTypes = (): string[] => {
        if (performances && performances.length) {
            return performances
                .map((p: Performance) => p.mediaType)
                .filter((value: string, index: number, array: string[]) => array.indexOf(value) === index);
        }

        return [];
    };

    const getPerformanceLabel = (p: Performance): string => {
        const labels = [];

        if (p?.mediaType) {
            labels.push(p.mediaType);
        }

        if (p?.campaignType) {
            labels.push(p.campaignType);
        }

        if (p?.strategyType) {
            labels.push(p.strategyType);
        }

        return labels.join(' ');
    };

    const [attributionMetrics, setAttributionMetrics] = useState<AttributionMetrics>(new AttributionMetrics());

    const fetchPerformances = useCallback(() => {
        if (isReportingCriteriaSetup && reportingCriteria) {
            setIsFetchingPerformances(true);

            ApiService.getCampaignPerformanceList({
                ...reportingCriteria,
            }).then((response) => {
                setIsFetchingPerformances(false);
                setPerformances(response.data.map((x: any) => ({ ...x })));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReportingCriteriaSetup, reportingCriteria]);

    const fetchAttributionPerformances = useCallback(() => {
        if (isReportingCriteriaSetup && reportingCriteria) {
            setIsFetchingAttributionPerformances(true);

            ApiService.getAttributionPerformanceList({
                ...reportingCriteria,
                dataSet: 'Sales',
            }).then((response) => {
                setIsFetchingAttributionPerformances(false);
                setAttributionPerformances(response.data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReportingCriteriaSetup, reportingCriteria]);

    const fetchActiveMediaTypes = useCallback(() => {
        if (isReportingCriteriaSetup && reportingCriteria) {
            ApiService.getCampaignPerformanceActiveMediaTypes({
                ...reportingCriteria,
            }).then((response) => {
                let newActiveMediaTypes: Record<string, string> = {};

                response.data.forEach((mediaType: string) => {
                    newActiveMediaTypes[mediaType] = mediaType;
                });

                setActiveMediaTypes(newActiveMediaTypes);
                setIsFetchingActiveMediaTypes(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReportingCriteriaSetup, reportingCriteria]);

    // const fetchAttributionStatus = useCallback(() => {
    //     if (dealer?.id) {
    //         setIsFetchingAttributionStatus(true);
    //         ApiService.getAttributionStatus(dealer.id).then((response) => {
    //             setIsFetchingAttributionStatus(false);
    //             setAttributionStatus(response.data as AttributionStatus);
    //         });
    //     }
    // }, [dealer]);
    useEffect(() => {
        setIsFetchingAttributionStatus(false);
    }, []);

    const isCompetitorPolkDealer = (polkDealer: PolkDealer): boolean => {
        let polkDealerDealer = null;

        if (polkDealerDealers && polkDealerDealers.length) {
            polkDealerDealer = polkDealerDealers.find((pdd: PolkDealerDealer) => pdd.polkDealer.id === polkDealer.id);
        }

        return polkDealerDealer ? polkDealerDealer.isCompetitor : false;
    };

    const isAdvertiserPolkDealer = (polkDealer: PolkDealer): boolean => {
        return isCompetitorPolkDealer(polkDealer) === false;
    };

    const getAdvertiserPolkDealerDealers = (): PolkDealerDealer[] => {
        return polkDealerDealers.filter((pdd: PolkDealerDealer) => pdd.isCompetitor === false);
    };

    const getCompetitorPolkDealerDealers = (): PolkDealerDealer[] => {
        return polkDealerDealers.filter((pdd: PolkDealerDealer) => pdd.isCompetitor === true);
    };

    useEffect(() => {
        if (attributionStatus?.salesWorkflows && attributionStatus.salesWorkflows.length) {
            setAttributionStatusUpdateDate(Utils.getDate(attributionStatus.salesWorkflows[0].updateDate));
        }
    }, [attributionStatus]);

    return {
        defaultMinDate,
        defaultMaxDate,
        defaultStartDate,
        defaultEndDate,

        isReportingCriteriaSetup,
        setIsReportingCriteriaSetup,
        reportingCriteria,
        setReportingCriteria,

        mediaTabs,
        performanceTabs,

        attributionStatus,
        setAttributionStatus,
        // fetchAttributionStatus,
        isFetchingAttributionStatus,

        attributionStatusUpdateDate,
        setAttributionStatusUpdateDate,
        attributionStatusUpdateDateFormatted,

        advertiserSalesUpdateDate,
        setAdvertiserSalesUpdateDate,
        advertiserSalesUpdateDateFormatted,

        polkDealerDealers,
        setPolkDealerDealers,
        isAdvertiserPolkDealer,
        isCompetitorPolkDealer,
        getAdvertiserPolkDealerDealers,
        getCompetitorPolkDealerDealers,

        polkSalesAnalysis,
        setPolkSalesAnalysis,

        polkSalesWorkflow,
        setPolkSalesWorkflow,
        polkOpportunitiesWorkflow,
        setPolkOpportunitiesWorkflow,

        performances,
        setPerformances,
        fetchPerformances,
        isFetchingPerformances,
        setIsFetchingPerformances,

        activeStrategyTypes,
        setActiveStrategyTypes,
        isActiveStrategyType,

        activeMediaTypes,
        setActiveMediaTypes,
        isActiveMediaType,
        fetchActiveMediaTypes,
        isFetchingActiveMediaTypes,
        setIsFetchingActiveMediaTypes,

        hasDisplayMediaType,
        hasVideoMediaType,
        hasAudioMediaType,

        attributionPerformances,
        setAttributionPerformances,
        fetchAttributionPerformances,
        isFetchingAttributionPerformances,
        setIsFetchingAttributionPerformances,

        getPerformances,
        getPerformanceMediaTypes,
        getPerformanceLabel,

        attributionMetrics,
        setAttributionMetrics,
    };
}
