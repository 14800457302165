import { ChangeEvent } from 'react';
import { FormControlLabel, Grid, GridProps, Switch, Tooltip, Typography } from '@mui/material';
import { AdminPanelSettingsOutlined as AdminPanelSettingsOutlinedIcon } from '@mui/icons-material';

import { Agency } from '../../../types/Agency';
import { USER_GROUP_ADMIN } from '../../../types/User';
import AccessGuard from '../../../components/AccessGuard';
import FormSection from '../../../components/FormSection';
import Row from '../../../components/Row';

const AdminOnlyActionIcon = () => {
    return (
        <Tooltip title="Visible to Admins Only">
            <AdminPanelSettingsOutlinedIcon color="primary" fontSize="small" />
        </Tooltip>
    );
};

const AdminOnlyFormControlLabel = ({ label }: { label: string }) => {
    return (
        <FormControlLabel
            control={
                <>
                    <Tooltip title={label}>
                        <Switch disabled={true} checked={true} />
                    </Tooltip>
                </>
            }
            label={
                <Row gap={1} alignItems="center">
                    {label}
                    <AdminOnlyActionIcon />
                </Row>
            }
        />
    );
};

const FormSectionGridContainer = (props: GridProps) => {
    return <Grid {...props} container rowSpacing={1} columnSpacing={2} />;
};

const FormSectionGridItem = (props: GridProps) => {
    return <Grid {...props} item xs={4} />;
};

interface AccessPermissionsProps {
    agency: Agency;
    setAgency: (agency: Agency) => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const AccessPermissions = (props: AccessPermissionsProps) => {
    const { agency, onChange } = props;

    return (
        <>
            <Grid container spacing={3}>
                <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                    <>
                        <Grid item xs={7}>
                            <FormSection>
                                <Typography variant="h6">Media Studio</Typography>
                                <FormSectionGridContainer>
                                    <FormSectionGridItem>
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="Create Campaigns">
                                                        <Switch
                                                            checked={agency.activeCampaigns}
                                                            onChange={onChange}
                                                            name="activeCampaigns"
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="Create Campaigns"
                                        />
                                    </FormSectionGridItem>
                                    <FormSectionGridItem>
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="Campaigns">
                                                        <Switch
                                                            checked={agency.activeCampaigns}
                                                            onChange={onChange}
                                                            name="activeCampaigns"
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="Campaigns"
                                        />
                                    </FormSectionGridItem>
                                    <FormSectionGridItem>
                                        <AdminOnlyFormControlLabel label="Create Campaigns 2.0" />
                                    </FormSectionGridItem>
                                </FormSectionGridContainer>
                            </FormSection>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid item>
                                <FormSection>
                                    <Typography variant="h6">Measurement Studio</Typography>
                                    <FormSectionGridContainer>
                                        <FormSectionGridItem>
                                            <FormControlLabel
                                                control={
                                                    <>
                                                        <Tooltip title="Reporting 2.0">
                                                            <Switch
                                                                checked={agency.activeReporting}
                                                                onChange={onChange}
                                                                name="activeReporting"
                                                            />
                                                        </Tooltip>
                                                    </>
                                                }
                                                label="Reporting 2.0"
                                            />
                                        </FormSectionGridItem>
                                        <FormSectionGridItem>
                                            <FormControlLabel
                                                control={
                                                    <>
                                                        <Tooltip title="Reporting">
                                                            <Switch
                                                                checked={agency.activeQuickSightReporting}
                                                                onChange={onChange}
                                                                name="activeQuickSightReporting"
                                                            />
                                                        </Tooltip>
                                                    </>
                                                }
                                                label="Reporting"
                                            />
                                        </FormSectionGridItem>
                                        <FormSectionGridItem>
                                            <FormControlLabel
                                                control={
                                                    <>
                                                        <Tooltip title="Attribution">
                                                            <Switch
                                                                checked={agency.activeAttribution}
                                                                onChange={onChange}
                                                                name="activeAttribution"
                                                            />
                                                        </Tooltip>
                                                    </>
                                                }
                                                label="Attribution"
                                            />
                                        </FormSectionGridItem>
                                        <FormSectionGridItem>
                                            <FormControlLabel
                                                control={
                                                    <>
                                                        <Tooltip title="Headless Analytics Tag (HAT)">
                                                            <Switch
                                                                checked={agency.tfUserIsActive}
                                                                onChange={onChange}
                                                                name="tfUserIsActive"
                                                            />
                                                        </Tooltip>
                                                    </>
                                                }
                                                label="Headless Analytics Tag (HAT)"
                                            />
                                        </FormSectionGridItem>
                                    </FormSectionGridContainer>
                                </FormSection>
                            </Grid>
                        </Grid>
                        <Grid item xs={7}>
                            <FormSection>
                                <Typography variant="h6">Data Studio</Typography>
                                <FormSectionGridContainer>
                                    <FormSectionGridItem>
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="First Party Data Upload">
                                                        <Switch
                                                            checked={agency.activeCrmUpload}
                                                            onChange={onChange}
                                                            name="activeCrmUpload"
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="First Party Data Upload"
                                        />
                                    </FormSectionGridItem>
                                    <FormSectionGridItem>
                                        <AdminOnlyFormControlLabel label="Import Manager" />
                                    </FormSectionGridItem>
                                    <FormSectionGridItem>
                                        <AdminOnlyFormControlLabel label="Data Mapping" />
                                    </FormSectionGridItem>
                                    <FormSectionGridItem>
                                        <AdminOnlyFormControlLabel label="Amazon Marketing Cloud" />
                                    </FormSectionGridItem>
                                    <FormSectionGridItem>
                                        <AdminOnlyFormControlLabel label="DSP Audiences" />
                                    </FormSectionGridItem>
                                </FormSectionGridContainer>
                            </FormSection>
                        </Grid>
                        <Grid item xs={7}>
                            <FormSection>
                                <Typography variant="h6">Ad Serving</Typography>
                                <AdminOnlyFormControlLabel label="" />
                            </FormSection>
                        </Grid>
                        <Grid item xs={7}>
                            <FormSection>
                                <Typography variant="h6">Creative Studio</Typography>
                                <FormControlLabel
                                    control={
                                        <>
                                            <Switch
                                                checked={agency.activeCreativeStudio}
                                                onChange={onChange}
                                                name="activeCreativeStudio"
                                            />
                                        </>
                                    }
                                    label=""
                                />
                            </FormSection>
                        </Grid>
                        <Grid item xs={7}>
                            <FormSection>
                                <Typography variant="h6">Planning & Insights</Typography>
                                <FormControlLabel
                                    control={
                                        <>
                                            <Tooltip title="Insights">
                                                <Switch
                                                    checked={agency.activeInsights}
                                                    onChange={onChange}
                                                    name="activeInsights"
                                                />
                                            </Tooltip>
                                        </>
                                    }
                                    label=""
                                />
                            </FormSection>
                        </Grid>
                        <Grid item xs={7}>
                            <FormSection>
                                <Typography variant="h6">Settings</Typography>
                                <FormSectionGridContainer>
                                    <FormSectionGridItem>
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Tooltip title="Advertisers">
                                                        <Switch
                                                            checked={agency.activeAdvertisers}
                                                            onChange={onChange}
                                                            name="activeAdvertisers"
                                                        />
                                                    </Tooltip>
                                                </>
                                            }
                                            label="Advertisers"
                                        />
                                    </FormSectionGridItem>
                                    <FormSectionGridItem>
                                        <AdminOnlyFormControlLabel label="Agencies" />
                                    </FormSectionGridItem>
                                    <FormSectionGridItem>
                                        <AdminOnlyFormControlLabel label="User Management" />
                                    </FormSectionGridItem>
                                    <FormSectionGridItem>
                                        <AdminOnlyFormControlLabel label="Analytics" />
                                    </FormSectionGridItem>
                                    <FormSectionGridItem>
                                        <AdminOnlyFormControlLabel label="Flashtalking Ad Tag Preview" />
                                    </FormSectionGridItem>
                                    <FormSectionGridItem>
                                        <AdminOnlyFormControlLabel label="Release Notes" />
                                    </FormSectionGridItem>
                                </FormSectionGridContainer>
                            </FormSection>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid item>
                                <FormSection>
                                    <Typography variant="h6">API Docs</Typography>
                                    <FormControlLabel
                                        control={
                                            <>
                                                <Tooltip title="API Docs">
                                                    <Switch
                                                        checked={agency.activeApiDocs}
                                                        onChange={onChange}
                                                        name="activeApiDocs"
                                                    />
                                                </Tooltip>
                                            </>
                                        }
                                        label=""
                                    />
                                </FormSection>
                            </Grid>
                        </Grid>
                    </>
                </AccessGuard>
            </Grid>
        </>
    );
};

export default AccessPermissions;
