import { Tab, Tabs } from '@mui/material';
import { EditOutlined, RefreshOutlined } from '@mui/icons-material';
import { Campaign, CampaignFee } from '../../../types/Campaign';
import Heading from '../../../components/Heading';
import CampaignDetailsHeader from './CampaignDetailsHeader';
import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Column from '../../../components/Column';
import { TabsContainer } from '../../../components/Tabs';
import TabPanel from '../../../components/TabPanel';
import TargetingTab from './TargetingTab';
import ApiService from '../../../ApiService';
import { UserContext } from '../../../App';
import FlightsTab from './FlightsTab';
import CreativeTab from './CreativeTab';
import SummaryTab from './SummaryTab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CampaignFeesComponent from './CampaignFeesComponent';
import Block from '../../../components/Block';
import Row from '../../../components/Row';
import CDButton from '../../../components/CDButton';
import ChangeRequestTab from './ChangeRequestTab';
import PerformanceTab from './PerformanceTab';

import { CampaignDetailsPageContext } from '../../../hooks/useCampaignDetailsPage';
import useCampaignDetailsPage from '../../../hooks/useCampaignDetailsPage';
import CampaignDeleteButton from './CampaignDeleteButton';
import CampaignArchiveButton from './CampaignArchiveButton';
import AdServerPlacementsTab from './AdServerPlacementsTab';

export const TABS = {
    PERFORMANCE: 0,
    SUMMARY: 1,
    TARGETING: 2,
    CREATIVE: 3,
    FLIGHTS: 4,
    AD_SERVER_PLACEMENTS: 5,
    FEES: 6,
    CHANGE_REQUESTS: 7,
};

const FT_SAS_ACCOUNT_ID = 2;
const VIDEO_MEDIA_TYPE = 'Video';

export default function CampaignDetailsPage() {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const id = params.id ? +params.id : null;

    const campaignDetailsPage = useCampaignDetailsPage();
    const { campaignChangeRequest } = campaignDetailsPage;

    const [selectedTab, setSelectedTab] = useState(location?.state?.tab ?? TABS.PERFORMANCE);
    const [campaign, setCampaign] = useState<Campaign | null>(null);
    const { userContext } = useContext(UserContext);

    useEffect(() => {
        getCampaign();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const isModifyButtonDisabled = useMemo((): boolean => {
        if (campaignChangeRequest !== null && campaignChangeRequest.status === 'Pending') {
            return true;
        }

        if (campaign) {
            return campaign.deliveryStatusId === 'ENDED' || campaign.deliveryStatusId === 'PENDING_COGNITION';
        }

        return false;
    }, [campaign, campaignChangeRequest]);

    const modifyButtonTooltip = useMemo((): string => {
        if (campaignChangeRequest !== null && campaignChangeRequest.status === 'Pending') {
            return 'You can not modify a campaign that already has a pending change request.';
        }

        return 'Request Campaign Changes';
    }, [campaignChangeRequest]);

    function getCampaign() {
        if (id) {
            ApiService.getCampaign(id)
                .then((response) => {
                    let campaign = response.data;
                    if (campaign.deliveryStatusId === 'PENDING_COGNITION' || campaign.hasUpdates) {
                        setSelectedTab(TABS.SUMMARY);
                    }
                    setCampaign(campaign);
                })
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        navigate('/notFound');
                    }
                });
        }
    }

    const showAdServerPlacements = () =>
        campaign?.dealer?.sasAccountId == FT_SAS_ACCOUNT_ID &&
        campaign?.dealer?.ftAdvertiserId &&
        userContext.isAdmin() &&
        campaign?.mediaType === VIDEO_MEDIA_TYPE;

    function refreshCampaignStatus() {
        if (campaign && campaign.id) {
            ApiService.refreshDspStatus(campaign.id).then((response) => {
                setCampaign(response.data);
            });
        }
    }

    function selectTab(_event: SyntheticEvent, value: any) {
        setSelectedTab(value);
    }

    if (!campaign) {
        return <></>;
    }
    return (
        <CampaignDetailsPageContext.Provider value={campaignDetailsPage}>
            <Column gap={0} flex={1}>
                <Block>
                    <Column gap={1}>
                        <AppBreadcrumbs items={[{ label: 'Campaigns', href: '/campaignManager' }]} />

                        <Row gap={1} sx={{ alignItems: 'center' }}>
                            <span>
                                <Heading>Campaign Details</Heading>
                            </span>
                            <span style={{ flex: 1 }} />
                            {userContext.isAdmin() && (
                                <>
                                    <CDButton
                                        tooltip="Get Latest status from DSP"
                                        //disabled={campaign.deliveryStatusId === 'ENDED'}
                                        startIcon={<RefreshOutlined />}
                                        onClick={refreshCampaignStatus}
                                    >
                                        Refresh
                                    </CDButton>
                                </>
                            )}
                            <CDButton
                                tooltip={modifyButtonTooltip}
                                disabled={isModifyButtonDisabled}
                                startIcon={<EditOutlined />}
                                onClick={() => {
                                    navigate('/campaign/' + campaign.id, {
                                        state: {
                                            isChangeRequest: true,
                                            campaignId: campaign.id,
                                            from: 'overview',
                                        },
                                    });
                                }}
                            >
                                Modify
                            </CDButton>
                            {userContext.isAdmin() && (
                                <CDButton
                                    tooltip={modifyButtonTooltip}
                                    disabled={isModifyButtonDisabled}
                                    startIcon={<EditOutlined />}
                                    onClick={() => {
                                        navigate('/campaign/v2/' + campaign.id, {
                                            state: {
                                                // isChangeRequest: true,
                                                // campaignId: campaign.id,
                                                // from: 'overview',
                                            },
                                        });
                                    }}
                                >
                                    Modify 2.0
                                </CDButton>
                            )}
                            {(userContext.isAdmin() || userContext.isAgency()) && (
                                <CampaignArchiveButton
                                    campaign={campaign}
                                    onCampaignArchive={() => {
                                        setTimeout(() => {
                                            navigate('/campaignManager');
                                        }, 2000);
                                    }}
                                />
                            )}
                            {userContext.isAdmin() && (
                                <CampaignDeleteButton
                                    campaign={campaign}
                                    onCampaignDelete={() => {
                                        setTimeout(() => {
                                            navigate('/campaignManager');
                                        }, 2000);
                                    }}
                                />
                            )}
                        </Row>
                    </Column>
                </Block>

                <Column gap={2} sx={{ height: '100%' }}>
                    <CampaignDetailsHeader campaign={campaign} />

                    <TabsContainer>
                        <Tabs value={selectedTab} onChange={selectTab}>
                            <Tab value={TABS.PERFORMANCE} label="Performance" />
                            <Tab value={TABS.SUMMARY} label="Summary" />
                            <Tab value={TABS.TARGETING} label="Targeting" />
                            {userContext.isAdmin() && <Tab value={TABS.CREATIVE} label="Creative" />}
                            {userContext.isAdmin() && <Tab value={TABS.FLIGHTS} label="Flights" />}
                            {showAdServerPlacements() && (
                                <Tab value={TABS.AD_SERVER_PLACEMENTS} label="Ad Server Placements" />
                            )}
                            {userContext.isAdmin() && <Tab value={TABS.FEES} label="Fees" />}
                            <Tab value={TABS.CHANGE_REQUESTS} label={'Change Requests'} />
                        </Tabs>
                    </TabsContainer>

                    <Block>
                        <TabPanel index={TABS.PERFORMANCE} value={selectedTab}>
                            <PerformanceTab campaign={campaign} />
                        </TabPanel>

                        <TabPanel index={TABS.SUMMARY} value={selectedTab}>
                            <SummaryTab campaign={campaign} reloadCampaign={getCampaign} />
                        </TabPanel>

                        <TabPanel index={TABS.TARGETING} value={selectedTab}>
                            <TargetingTab campaign={campaign} onChange={() => {}} />
                        </TabPanel>

                        {userContext.isAdmin() && (
                            <TabPanel index={TABS.CREATIVE} value={selectedTab}>
                                <CreativeTab campaign={campaign} />
                            </TabPanel>
                        )}

                        {userContext.isAdmin() && (
                            <TabPanel index={TABS.FLIGHTS} value={selectedTab}>
                                <FlightsTab campaign={campaign} reloadCampaign={getCampaign} />
                            </TabPanel>
                        )}

                        <TabPanel index={TABS.AD_SERVER_PLACEMENTS} value={selectedTab}>
                            <AdServerPlacementsTab campaign={campaign} reloadCampaign={getCampaign} />
                        </TabPanel>

                        {userContext.isAdmin() && (
                            <TabPanel index={TABS.FEES} value={selectedTab}>
                                <CampaignFeesComponent
                                    campaignFee={campaign.campaignFee}
                                    allowEditToggle={userContext.isAdmin() && campaign.deliveryStatusId !== 'ENDED'}
                                    editMode={false}
                                    onChange={(campaignFee: CampaignFee) => {
                                        const updatedCampaign = { ...campaign, campaignFee: campaignFee };
                                        setCampaign(updatedCampaign);
                                        ApiService.updateCampaign(updatedCampaign).then((response) => {
                                            setCampaign(response.data);
                                        });
                                    }}
                                />
                            </TabPanel>
                        )}

                        <TabPanel index={TABS.CHANGE_REQUESTS} value={selectedTab}>
                            <ChangeRequestTab campaign={campaign} />
                        </TabPanel>
                    </Block>
                </Column>
            </Column>
        </CampaignDetailsPageContext.Provider>
    );
}
