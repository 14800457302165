export enum TrackingStatus {
    NEW = 'NEW',
    ALIVE = 'ALIVE',
    DEAD = 'DEAD',
}

interface Tracking {
    status: TrackingStatus | null;
}

export type { Tracking };
