import { useState } from 'react';
import { Divider, Tooltip } from '@mui/material';
import {
    AdminPanelSettingsOutlined as AdminPanelSettingsOutlinedIcon,
    CodeOutlined as CodeOutlinedIcon,
    SettingsOutlined as SettingsOutlinedIcon,
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

import { hasAccess } from '../AccessGuard';
import { UserContextValue } from '../../hooks/Contexts/UserContext';
import {
    AGENCY_CREATIVE_STUDIO,
    AGENCY_HAT,
    USER_GROUP_ADMIN,
    USER_GROUP_ADVERTISER,
    USER_GROUP_AGENCY,
} from '../../types/User';
import { SidebarMenuItem } from './index';
import Column from '../Column';
import SidebarMenuAvatar from './SidebarMenuAvatar';
import SidebarMenuList from './SidebarMenuList';

const MediaStudioAvatar = () => {
    return <SidebarMenuAvatar sx={{ bgcolor: '#ee5aa3' }}>Me</SidebarMenuAvatar>;
};

const MeasurementStudioAvatar = () => {
    return <SidebarMenuAvatar sx={{ bgcolor: '#f59e0b' }}>Ma</SidebarMenuAvatar>;
};

const DataStudioAvatar = () => {
    return <SidebarMenuAvatar sx={{ bgcolor: '#e16bf2' }}>Da</SidebarMenuAvatar>;
};

const AdServingStudioAvatar = () => {
    return <SidebarMenuAvatar sx={{ bgcolor: '#976cf7' }}>As</SidebarMenuAvatar>;
};

const CreativeStudioAvatar = () => {
    return <SidebarMenuAvatar sx={{ bgcolor: '#28c08e' }}>Cs</SidebarMenuAvatar>;
};

const PlanningStudioAvatar = () => {
    return <SidebarMenuAvatar sx={{ bgcolor: '#26aeeb' }}>Pi</SidebarMenuAvatar>;
};

const AdminOnlyActionIcon = () => {
    return (
        <Tooltip title="Visible to Admins Only">
            <AdminPanelSettingsOutlinedIcon color="primary" fontSize="small" />
        </Tooltip>
    );
};

interface SidebarNavigationState {
    primary: {
        items: SidebarMenuItem[];
    };
    secondary: {
        items: SidebarMenuItem[];
    };
}

const getUrl = (userContext: UserContextValue, name: string): string => {
    switch (name) {
        case 'CREATIVE_STUDIO':
            return hasAccess(userContext, ``, AGENCY_CREATIVE_STUDIO)
                ? 'https://creative.cognitionads.com/'
                : '/accessDenied';

        case 'HAT':
            return hasAccess(userContext, ``, AGENCY_HAT) ? 'http://app.trialfire.com/' : '/accessDenied';
    }

    return '';
};

const getTarget = (userContext: UserContextValue, name: string): string => {
    let access: boolean = false;

    switch (name) {
        case 'CREATIVE_STUDIO':
            access = hasAccess(userContext, ``, AGENCY_CREATIVE_STUDIO);
            break;

        case 'HAT':
            access = hasAccess(userContext, ``, AGENCY_HAT);
            break;
    }

    return access ? '_blank' : '';
};

const SidebarNavigation = () => {
    const location = useLocation();

    const [navigation, setNavigation] = useState<SidebarNavigationState>({
        primary: {
            items: [
                {
                    accessGroup: `${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`,
                    name: 'Media Studio',
                    icon: <MediaStudioAvatar />,
                    children: [
                        {
                            name: 'Create Campaign',
                            url: '/campaign/create',
                        },
                        {
                            accessGroup: `${USER_GROUP_ADMIN}`,
                            name: 'Create Campaign 2.0',
                            url: '/campaign/v2/create',
                            actionIcon: <AdminOnlyActionIcon />,
                        },
                        {
                            name: 'Campaigns',
                            url: '/campaignManager',
                        },
                    ],
                },
                {
                    accessGroup: `${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`,
                    name: 'Measurement Studio',
                    icon: <MeasurementStudioAvatar />,
                    children: [
                        {
                            name: 'Reporting 2.0',
                            url: '/reporting',
                        },
                        {
                            name: 'Reporting',
                            url: '/quicksight',
                        },
                        {
                            name: 'Attribution',
                            url: '/attribution',
                        },
                        {
                            name: 'Headless Analytics Tag (HAT)',
                            getUrl: (item: SidebarMenuItem, userContext: UserContextValue): string => {
                                return getUrl(userContext, 'HAT');
                            },
                            getTarget: (item: SidebarMenuItem, userContext: UserContextValue): string => {
                                return getTarget(userContext, 'HAT');
                            },
                        },
                    ],
                },
                {
                    accessGroup: `${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`,
                    name: 'Data Studio',
                    icon: <DataStudioAvatar />,
                    children: [
                        {
                            accessGroup: `${USER_GROUP_ADMIN}`,
                            name: 'Import Manager',
                            url: '/importManager',
                            actionIcon: <AdminOnlyActionIcon />,
                        },
                        {
                            name: 'First Party Data Upload',
                            url: '/crmUpload',
                        },
                        {
                            accessGroup: `${USER_GROUP_ADMIN}`,
                            name: 'Data Mapping',
                            url: '/dataMapping',
                            actionIcon: <AdminOnlyActionIcon />,
                        },
                        {
                            accessGroup: `${USER_GROUP_ADMIN}`,
                            name: 'Amazon Marketing Cloud',
                            url: '/amcInstances',
                            actionIcon: <AdminOnlyActionIcon />,
                            isActive: (item: SidebarMenuItem): boolean => {
                                switch (location.pathname) {
                                    case '/amcInstances':
                                    case '/amcDataSets':
                                    case '/workflows':
                                    case '/amcworkflows':
                                    case '/amcAttributionList':
                                        return true;
                                }
                                return false;
                            },
                        },
                        // {
                        //     accessGroup: `${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`,
                        //     accessAgency: AGENCY_DATA,
                        //     name: 'AMC Instances',
                        //     url: '/amcInstances',
                        //     // actionIcon: <AdminOnlyActionIcon />,
                        // },
                        // {
                        //     accessGroup: `${USER_GROUP_ADMIN}`,
                        //     name: 'AMC Data Sets',
                        //     url: '/amcDataSets',
                        //     actionIcon: <AdminOnlyActionIcon />,
                        // },
                        // {
                        //     accessGroup: `${USER_GROUP_ADMIN}`,
                        //     name: 'AMC Global Workflows',
                        //     url: '/workflows',
                        //     actionIcon: <AdminOnlyActionIcon />,
                        // },
                        // {
                        //     accessGroup: `${USER_GROUP_ADMIN}`,
                        //     name: 'AMC Workflows',
                        //     url: '/amcworkflows',
                        //     actionIcon: <AdminOnlyActionIcon />,
                        // },
                        // {
                        //     accessGroup: `${USER_GROUP_ADMIN}`,
                        //     name: 'AMC Offline Attributions',
                        //     url: '/amcAttributionList',
                        //     actionIcon: <AdminOnlyActionIcon />,
                        // },
                        {
                            accessGroup: `${USER_GROUP_ADMIN}`,
                            name: 'DSP Audiences',
                            url: '/dspAudiences',
                            actionIcon: <AdminOnlyActionIcon />,
                        },
                    ],
                },
                {
                    accessGroup: `${USER_GROUP_ADMIN}`,
                    name: 'Ad Serving',
                    url: '/adServing',
                    icon: <AdServingStudioAvatar />,
                    actionIcon: <AdminOnlyActionIcon />,
                },
                {
                    accessGroup: `${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`,
                    name: 'Creative Studio',
                    getUrl: (item: SidebarMenuItem, userContext: UserContextValue): string => {
                        return getUrl(userContext, 'CREATIVE_STUDIO');
                    },
                    getTarget: (item: SidebarMenuItem, userContext: UserContextValue): string => {
                        return getTarget(userContext, 'CREATIVE_STUDIO');
                    },
                    icon: <CreativeStudioAvatar />,
                },
                {
                    accessGroup: `${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`,
                    name: 'Planning & Insights',
                    url: '/insights',
                    icon: <PlanningStudioAvatar />,
                },
            ],
        },
        secondary: {
            items: [
                {
                    accessGroup: `${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`,
                    name: 'Settings',
                    icon: <SettingsOutlinedIcon fontSize="small" />,
                    children: [
                        {
                            accessGroup: `${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`,
                            name: 'Advertisers',
                            url: '/advertisers',
                        },
                        {
                            accessGroup: `${USER_GROUP_ADMIN}`,
                            name: 'Agencies',
                            url: '/agencies',
                            actionIcon: <AdminOnlyActionIcon />,
                        },
                        {
                            accessGroup: `${USER_GROUP_ADMIN}`,
                            name: 'User Management',
                            url: '/users',
                            actionIcon: <AdminOnlyActionIcon />,
                        },
                        {
                            accessGroup: `${USER_GROUP_ADMIN}`,
                            name: 'Analytics',
                            url: '/analyticsSettings',
                            actionIcon: <AdminOnlyActionIcon />,
                        },
                        {
                            accessGroup: `${USER_GROUP_ADMIN}`,
                            name: 'Flashtalking Ad Tag Preview',
                            url: '/adTagPreview',
                            actionIcon: <AdminOnlyActionIcon />,
                        },
                        {
                            accessGroup: `${USER_GROUP_ADMIN}`,
                            name: 'Ops Dashboard',
                            url: '/opsDashboard',
                            actionIcon: <AdminOnlyActionIcon />,
                        },
                        {
                            accessGroup: `${USER_GROUP_ADMIN}`,
                            name: 'Release Notes',
                            url: '/releaseNotes',
                            actionIcon: <AdminOnlyActionIcon />,
                        },
                    ],
                },
                {
                    accessGroup: `${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`,
                    name: 'API Docs',
                    icon: <CodeOutlinedIcon fontSize="small" />,
                    children: [
                        {
                            name: 'Analytics API',
                            url: 'https://documenter.getpostman.com/view/32580146/2sA3JQ5Kgb',
                            target: '_blank',
                        },
                        {
                            name: 'Creative API',
                            url: 'https://documenter.getpostman.com/view/32580146/2sA2r81PB8',
                            target: '_blank',
                        },
                        {
                            name: 'HAT API',
                            url: 'https://docs.lassoo.io/',
                            target: '_blank',
                        },
                    ],
                },
            ],
        },
    });

    return (
        <Column>
            <Column gap={1}>
                <SidebarMenuList
                    items={navigation.primary.items}
                    onCollapse={(item: SidebarMenuItem, index: number): void => {
                        if (index in navigation.primary.items) {
                            navigation.primary.items[index] = item;
                            setNavigation({ ...navigation });
                        }
                    }}
                />

                <Divider />

                <SidebarMenuList
                    items={navigation.secondary.items}
                    onCollapse={(item: SidebarMenuItem, index: number): void => {
                        if (index in navigation.secondary.items) {
                            navigation.secondary.items[index] = item;
                            setNavigation({ ...navigation });
                        }
                    }}
                />
            </Column>
        </Column>
    );
};

export default SidebarNavigation;
