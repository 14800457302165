import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
    AlertColor,
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Tooltip,
} from '@mui/material';
import {
    AdCreativeSource,
    AdSize,
    AdTagImportSource,
    AdTagPlacement,
    AdTagPlacementData,
    AdTagPlacementStatus,
    AdTagTemplate,
    AdTagType,
    DspPlatform,
    Project,
} from '../../types/AdTag';
import { FileInfo } from '../../types/S3';
import ApiService from '../../ApiService';
import CDButton from '../../components/CDButton';
import Row from '../../components/Row';
import { CDTextField } from '../../components/CDTextField';
import AppLoader from '../../components/AppLoader';
import CDAutocompleteEndless from '../../components/CDAutocompleteEndless';
import { Dealer } from '../../types/Dealer';
import { Campaign, CampaignAdGroup } from '../../types/Campaign';
import AccessGuard from '../../components/AccessGuard';
import { AGENCY_CAMPAIGNS } from '../../types/User';

const initialValues = {
    id: undefined as number | undefined,
    dealer: null as Dealer | null,
    campaign: null as Campaign | null,
    campaignAdGroup: null as CampaignAdGroup | null,
    tagType: 'VAST_VIDEO' as keyof typeof AdTagType,
    adTagTemplateId: 1, // "Static Image HTML" as default
    adSize: '160x600' as keyof typeof AdSize,
    dspPlatform: 'AMAZON' as keyof typeof DspPlatform,
    adCreativeSource: 'FTP' as keyof typeof AdCreativeSource,
    adTagImportSourceId: null as number | null,
    filenameKey: null as string | null,
    filename: null as string | null,
    projectId: undefined as string | undefined,
    adUrl: undefined as string | undefined,
    adName: null as string | null,
    clickThroughUrl: undefined as string | undefined,
    impressionUrl: undefined as string | undefined,
    status: undefined as string | undefined,
    project: undefined as Project | undefined,
};

export type EditAdTagPlacementRef = {
    edit: (agencyId: number | null, adTagPlacement?: AdTagPlacement) => void;
};

type Props = {
    reloadGrid(): void;
    showInfoMessage: (alertColor: AlertColor, message: string, onClose?: () => void) => void;
};

const popperProps = {
    sx: { '& .MuiTooltip-tooltip': { backgroundColor: 'white', color: 'black', boxShadow: 1, fontSize: 14 } },
};

const EditAdTagPlacement = forwardRef<EditAdTagPlacementRef, Props>(({ reloadGrid, showInfoMessage }, ref) => {
    const [sources, setSources] = useState<AdTagImportSource[]>([]);
    const [campaigns, setCampaigns] = useState<Campaign[] | null>(null);
    const [adTagTemplates, setAdTagTemplates] = useState<AdTagTemplate[] | null>(null);
    const [mediaFiles, setMediaFiles] = useState<FileInfo[] | null>(null);
    // const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
    //     message: null,
    // });
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState(initialValues);
    const [agencyId, setAgencyId] = useState<number | null>(null);

    const [isLoading, setIsLoading] = useState(false);

    const fetchCampaigns = (dealerId: number, campaignId?: number) => {
        setIsLoading(true);
        ApiService.getCampaignsForDealer(dealerId)
            .then(({ data: campaigns }) => {
                setCampaigns(campaigns);
                if (campaignId) {
                    const cmp = campaigns.find((c) => c.id === campaignId);
                    if (cmp) setFormData((prev) => ({ ...prev, campaign: cmp }));
                }
            })
            .finally(() => setIsLoading(false));
    };

    const fetchMediaFiles = (adTagImportSourceId: number, tagType: keyof typeof AdTagType) => {
        ApiService.getAdTagIncomingS3Files(
            adTagImportSourceId,
            tagType === 'VAST_VIDEO' ? '.mp4' : '.jpg,.jpeg,.png'
        ).then(({ data }) => setMediaFiles(data));
    };

    const fetchAdTagTemplates = () => ApiService.getTemplates().then(({ data }) => setAdTagTemplates(data));

    useImperativeHandle(ref, () => ({
        edit(agencyId: number | null, atp?: AdTagPlacement) {
            setAgencyId(agencyId);
            if (atp) {
                const project = {
                    id: atp.projectId || '',
                    output: atp.adName || '',
                } as Project;
                setFormData((prev) => ({
                    ...prev,
                    id: atp.id,
                    dealer: atp.dealer,
                    campaignAdGroup: atp.campaignAdGroup,
                    tagType: atp.tagType,
                    adTagTemplateId: atp.adTagTemplateId || 1,
                    adSize: atp.adSize || '160x600',
                    dspPlatform: atp.dspPlatform,
                    adCreativeSource: atp.adCreativeSource,
                    adTagImportSourceId: atp.adTagImportSourceId,
                    filename: atp.filename,
                    filenameKey: atp.filenameKey,
                    adName: atp.adName,
                    impressionUrl: atp.impressionUrl || undefined,
                    clickThroughUrl: atp.clickThroughUrl || undefined,
                    project,
                    projectId: atp.projectId || undefined,
                    adUrl: atp.adUrl || undefined,
                    status: atp.status || undefined,
                }));

                // fetch campaigns for the dealer and set the campaign
                if (atp.dealer?.id) fetchCampaigns(atp.dealer.id, atp.campaign?.id);
                if (atp.adTagImportSourceId) fetchMediaFiles(atp.adTagImportSourceId, atp.tagType);
                if (!adTagTemplates && atp.tagType === 'HTML_IMAGE') fetchAdTagTemplates();
            }
            handleDialogToggle();
        },
    }));

    useEffect(() => {
        if (!isOpen) return;
        if (sources.length) return;
        ApiService.getAdTagImportSources()
            .then((response) => setSources(response.data))
            .catch(() => {});
    }, [isOpen]);

    useEffect(() => {
        const { adCreativeSource, dealer, dspPlatform, filenameKey } = formData;
        if (adCreativeSource === 'CREATIVE_STUDIO') return;
        if (!dealer?.dealerName || !dspPlatform || !filenameKey) return;
        setFormData((prev) => ({
            ...prev,
            adName: prev.adName || [dealer?.dealerName, dspPlatform, filenameKey].join(' - '),
        }));
    }, [formData.dealer, formData.dspPlatform, formData.filenameKey]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const {
            id,
            dealer,
            campaign,
            campaignAdGroup,
            tagType,
            adTagTemplateId,
            adSize,
            dspPlatform,
            projectId,
            adUrl,
            adName,
            clickThroughUrl,
            impressionUrl,
            adCreativeSource,
            adTagImportSourceId,
            filenameKey,
            filename,
            status,
        } = formData;

        const preparedData: AdTagPlacementData = {
            id,
            dealerId: dealer?.id || 0,
            campaignId: campaign?.id,
            campaignAdGroupId: campaignAdGroup?.id,
            dspPlatform,
            projectId,
            adUrl,
            adName,
            clickThroughUrl: clickThroughUrl,
            impressionUrl: impressionUrl,
            tagType,
            adTagTemplateId,
            adSize,
            adCreativeSource,
            adTagImportSourceId,
            filenameKey,
            filename,
            status,
        };

        let { data: created } = await ApiService.createAdTagPlacement(preparedData);
        handleDialogClose();
        reloadGrid();
        if (created.id && adCreativeSource !== 'CREATIVE_STUDIO') {
            let { data: published } = await ApiService.publishAdTagPlacement(created.id);
            if (published.status === AdTagPlacementStatus.ERROR) {
                showInfoMessage('error', `Error processing ${published.adName}: ${published.processMessage}`);
            }
        }
    };

    const handleDealerChange = (dealer: Dealer | null) => {
        setCampaigns(null);
        const clickThroughUrl = dealer?.url || '';
        setFormData((prev) => ({ ...prev, dealer, clickThroughUrl, campaign: null, campaignAdGroup: null }));
        if (dealer?.id) fetchCampaigns(dealer.id);
    };

    const handleCampaignChange = (event: React.ChangeEvent<{}>, campaign: Campaign | null) => {
        setFormData((prev) => ({ ...prev, campaign, campaignAdGroup: null }));
    };

    const handleAdGroupChange = (event: React.ChangeEvent<{}>, value: any) => {
        setFormData((prev) => ({ ...prev, campaignAdGroup: value }));
    };

    const handleAdTagTypeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMediaFiles(null);
        setFormData((prev) => ({
            ...prev,
            tagType: event.target.value as keyof typeof AdTagType,
            adTagImportSourceId: null,
            project: undefined,
            adName: null,
            projectId: undefined,
            adUrl: undefined,
            status: undefined,
        }));
        if (!adTagTemplates && event.target.value === 'HTML_IMAGE') fetchAdTagTemplates();
    };

    const handleAdTagTemplateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const templateId: any = +event.target.value;
        setFormData((prev) => ({ ...prev, adTagTemplateId: templateId }));
    };

    const handleAdSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prev) => ({ ...prev, adSize: event.target.value as keyof typeof AdSize }));
    };

    const handleDspPlatformChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prev) => ({ ...prev, dspPlatform: event.target.value as keyof typeof DspPlatform }));
    };

    const handleAdCreativeSourceChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prev) => ({ ...prev, adCreativeSource: event.target.value as keyof typeof AdCreativeSource }));
    };

    const handleSourceChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const adTagImportSourceId: any = +event.target.value;
        setMediaFiles(null);
        setFormData((prev) => ({ ...prev, adTagImportSourceId, filenameKey: null, filename: null }));
        fetchMediaFiles(adTagImportSourceId, formData.tagType);
    };

    const handleVideoFileChange = (event: React.ChangeEvent<{}>, value: string | null) => {
        const filename: any = value || '';
        const filenameKey: any = filename.split('.')[0] || '';
        setFormData((prev) => ({ ...prev, filenameKey, filename }));
    };

    const handleAdNameChange = (project: Project) => {
        const projectId = project.id;
        const adName = project.output || '';
        const adUrl = project['render-upload-s3'].list.find((f: any) => f.name.match(/html|vast/i))?.url;
        const status = project['render-status'];
        setFormData((prev) => ({ ...prev, project, adName, projectId, adUrl, status }));
    };

    const clearFormData = () => {
        setFormData(initialValues);
        setCampaigns(null);
        setMediaFiles(null);
    };

    const handleDialogToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleDialogClose = () => {
        handleDialogToggle();
        clearFormData();
    };

    const renderAdNameAutocomplete = (tagType: keyof typeof AdTagType) => {
        return (
            <CDAutocompleteEndless
                sx={{ padding: '10px' }}
                required
                label="Select Ad Tag Name"
                parentId={null}
                value={formData.project}
                onChange={handleAdNameChange}
                handleFetch={async (page, output) => {
                    return ApiService.getAdTagProjects(page, output, tagType);
                }}
                getOptionLabel={(option: Project) => option.output}
            />
        );
    };

    const renderAdNameHTML = () => {
        return formData.tagType === 'HTML_IMAGE' && renderAdNameAutocomplete('HTML_IMAGE');
    };

    const renderAdNameVAST = () => {
        return formData.tagType === 'VAST_VIDEO' && renderAdNameAutocomplete('VAST_VIDEO');
    };

    return (
        <>
            <Dialog open={isOpen} maxWidth="md" fullWidth>
                <AppLoader loading={isLoading} />

                <DialogTitle>{formData.id ? 'Edit' : 'Setup'} Ad Tag</DialogTitle>

                {isOpen && (
                    <form onSubmit={handleSubmit}>
                        <DialogContent sx={{ padding: '10px' }}>
                            <CDAutocompleteEndless
                                sx={{ padding: '10px' }}
                                required
                                label="Select Advertiser"
                                parentId={agencyId}
                                value={formData.dealer}
                                onChange={handleDealerChange}
                                handleFetch={async (page, name, parentId) =>
                                    ApiService.getAllDealers(page, name, parentId)
                                }
                                getOptionLabel={(option: Dealer) => option.dealerName}
                            />

                            <Row sx={{ padding: '10px' }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Select Creative Source"
                                    select
                                    value={formData.adCreativeSource}
                                    onChange={handleAdCreativeSourceChange}
                                    SelectProps={{ native: false }}
                                >
                                    {Object.keys(AdCreativeSource).map((key) => (
                                        <MenuItem key={key} value={key}>
                                            {AdCreativeSource[key as keyof typeof AdCreativeSource]}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Row>

                            <Row sx={{ padding: '10px' }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Select Tag Type"
                                    select
                                    value={formData.tagType}
                                    onChange={handleAdTagTypeChange}
                                    SelectProps={{ native: false }}
                                >
                                    {Object.keys(AdTagType).map((key) => (
                                        <MenuItem key={key} value={key}>
                                            {AdTagType[key as keyof typeof AdTagType]}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Row>

                            {adTagTemplates &&
                                formData.tagType === 'HTML_IMAGE' &&
                                formData.adCreativeSource === 'FTP' && (
                                    <>
                                        <Row sx={{ padding: '10px' }}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                label="Select Tag Template"
                                                select
                                                value={formData.adTagTemplateId}
                                                onChange={handleAdTagTemplateChange}
                                                SelectProps={{ native: false }}
                                            >
                                                {adTagTemplates.map((t) => (
                                                    <MenuItem key={t.id} value={t.id}>
                                                        {t.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Row>
                                        <Row sx={{ padding: '10px' }}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                label="Select Ad Size"
                                                select
                                                value={formData.adSize}
                                                onChange={handleAdSizeChange}
                                                SelectProps={{ native: false }}
                                            >
                                                {Object.keys(AdSize).map((key) => (
                                                    <MenuItem key={key} value={key}>
                                                        {AdSize[key as keyof typeof AdSize]}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Row>
                                    </>
                                )}

                            <Row sx={{ padding: '10px' }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Select DSP Platform"
                                    select
                                    value={formData.dspPlatform}
                                    onChange={handleDspPlatformChange}
                                    SelectProps={{ native: false }}
                                >
                                    {Object.keys(DspPlatform).map((key) => (
                                        <MenuItem key={key} value={key}>
                                            {DspPlatform[key as keyof typeof DspPlatform]}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Row>

                            {formData.adCreativeSource === 'FTP' && (
                                <>
                                    <Row sx={{ padding: '10px' }}>
                                        <TextField
                                            fullWidth
                                            required
                                            size="small"
                                            label="Select Source Folder"
                                            select
                                            onChange={handleSourceChange}
                                            value={formData.adTagImportSourceId || ''}
                                            SelectProps={{ native: false }}
                                        >
                                            {sources.map((source) => (
                                                <MenuItem key={source.id} value={source.id}>
                                                    {source.s3Bucket}/{source.s3IncomingPath}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Row>
                                    {mediaFiles && (
                                        <Tooltip
                                            title="Files that are already assigned to a tag will not appear in this list."
                                            placement="left"
                                            PopperProps={popperProps}
                                        >
                                            <Autocomplete
                                                sx={{ padding: '10px' }}
                                                options={mediaFiles.map((file) => file.name)}
                                                value={formData.filename}
                                                onChange={handleVideoFileChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        required
                                                        size="small"
                                                        label="Select File"
                                                        name="filename"
                                                    />
                                                )}
                                            />
                                        </Tooltip>
                                    )}

                                    <Row sx={{ padding: '10px' }}>
                                        <CDTextField
                                            fullWidth
                                            required
                                            name="filenameKey"
                                            label="Filename Key"
                                            variant="outlined"
                                            value={formData.filenameKey}
                                            onChange={handleChange}
                                        />
                                    </Row>

                                    <Row sx={{ padding: '10px' }}>
                                        <CDTextField
                                            fullWidth
                                            required
                                            name="adName"
                                            label="Ad Tag Name"
                                            variant="outlined"
                                            value={formData.adName}
                                            onChange={handleChange}
                                        />
                                    </Row>
                                </>
                            )}
                            {formData.adCreativeSource === 'CREATIVE_STUDIO' && (
                                <Tooltip
                                    title="Creative Studio Ads that were not generated with an HTML or VAST Tag will not appear in this list."
                                    placement="left"
                                    PopperProps={popperProps}
                                >
                                    <span>
                                        {renderAdNameHTML()}
                                        {renderAdNameVAST()}
                                    </span>
                                </Tooltip>
                            )}
                            <Row sx={{ padding: '10px' }}>
                                <CDTextField
                                    fullWidth
                                    name="clickThroughUrl"
                                    label="Click-Through URL"
                                    variant="outlined"
                                    value={formData.clickThroughUrl}
                                    onChange={handleChange}
                                />
                            </Row>
                            <Row sx={{ padding: '10px' }}>
                                <CDTextField
                                    fullWidth
                                    name="impressionUrl"
                                    label="Impression URL"
                                    variant="outlined"
                                    value={formData.impressionUrl}
                                    onChange={handleChange}
                                />
                            </Row>
                            <AccessGuard accessAgency={`${AGENCY_CAMPAIGNS}`}>
                                <>
                                    {campaigns && (
                                        <Autocomplete
                                            sx={{ padding: '10px' }}
                                            options={campaigns}
                                            value={formData.campaign}
                                            getOptionLabel={(campaign) => campaign.campaignName || ''}
                                            onChange={handleCampaignChange}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth size="small" label="Select Campaign" />
                                            )}
                                        />
                                    )}

                                    {formData?.campaign?.adGroups && (
                                        <Autocomplete
                                            sx={{ padding: '10px' }}
                                            options={formData?.campaign?.adGroups}
                                            value={formData.campaignAdGroup}
                                            getOptionLabel={(ag) => ag.name || ''}
                                            onChange={handleAdGroupChange}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth size="small" label="Select Ad Group" />
                                            )}
                                        />
                                    )}
                                </>
                            </AccessGuard>
                        </DialogContent>

                        <DialogActions sx={{ padding: '10px 20px' }}>
                            <CDButton color="error" onClick={handleDialogClose}>
                                Cancel
                            </CDButton>

                            <CDButton type="submit" disabled={isLoading}>
                                Generate Ad Tag
                            </CDButton>
                        </DialogActions>
                    </form>
                )}
            </Dialog>
        </>
    );
});

export default EditAdTagPlacement;
