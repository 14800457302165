import { useEffect, useState } from 'react';

import { Autocomplete, TextField, Box, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import ApiService from '../ApiService';
import Masonry from '@mui/lab/Masonry';
import Paper from '@mui/material/Paper';
import { AdCreative, AssetFileMetadata } from '../types/AmazonAds';
import { Campaign, CampaignAdGroup } from '../types/Campaign';
import CDMonthDatePicker from './CDMonthDatePicker';
import dayjs, { Dayjs } from 'dayjs';

export interface AdvertiserAdComponentProps {
    campaign?: Campaign;
    dealerId?: number;
    disabled?: boolean;
}

export default function AdvertiserAdComponent(props: AdvertiserAdComponentProps) {
    const [adCreativeMap, setAdCreativeMap] = useState<Map<string, AdCreative[]>>(new Map());
    const [adGroups, setAdGroups] = useState<CampaignAdGroup[]>([]);
    const [adGroup, setAdGroup] = useState<CampaignAdGroup | null>(null);
    const [adGroupsByDate, setAdGroupsByDate] = useState<Map<string, CampaignAdGroup[]>>(new Map());
    const [selectedDate, setSelectedDate] = useState<Dayjs | Date | string>(dayjs());
    const [selectedMonthYear, setSelectedMonthYear] = useState<string>('');

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const generateAdGroupsMap = () => {
        const adGroupsMapByDate: Map<string, CampaignAdGroup[]> = new Map();
        props.campaign?.adGroups.forEach((adGroup: CampaignAdGroup) => {
            if (adGroup.creationDateTime) {
                const creationDate = new Date(adGroup.creationDateTime);
                const month = creationDate.getUTCMonth() + 1; // months from 1-12
                const year = creationDate.getUTCFullYear();
                const yearMonth = `${year}-${month}`;
                let adGroups = adGroupsMapByDate.get(yearMonth);
                if (!adGroups) {
                    adGroups = [];
                }
                adGroups?.push(adGroup);
                adGroupsMapByDate.set(yearMonth, adGroups);
            }
        });
        setAdGroupsByDate(adGroupsMapByDate);
    };
    const fetchAdCreatives = (dealerId: number) => {
        ApiService.getDealerDspAdCreatives(dealerId)
            .then((response) => {
                const creativeMap: Map<string, AdCreative[]> = new Map();
                response.data.forEach((creative: AdCreative) => {
                    creative.assets.forEach((asset) => {
                        if (asset.assetSubTypes !== 'THIRD_PARTY_DISPLAY') {
                            const parsedMetadata: AssetFileMetadata = JSON.parse(asset.fileMetadata);
                            asset.fileMetadataObject = parsedMetadata;
                        } else {
                            const metadata: AssetFileMetadata = {
                                contentType: 'HTML',
                                durationInSeconds: 0,
                                extension: '',
                                height: 0,
                                width: 0,
                                resolutionHeight: 0,
                                resolutionWidth: 0,
                                sizeInBytes: 0,
                                audioSampleRateInHz: 0,
                                aspectRatio: '',
                            };
                            asset.fileMetadataObject = metadata;
                        }
                    });

                    creative.associations.forEach((association) => {
                        let creatives = creativeMap.get(association.dspAdGroupId);
                        if (!creatives) {
                            creatives = [];
                        }
                        if (!creatives.includes(creative)) {
                            creatives?.push(creative);
                        }
                        creativeMap.set(association.dspAdGroupId, creatives);
                    });
                });
                setAdCreativeMap(creativeMap);
                // setEnabled(true);
            })
            .catch(() => {});
    };

    useEffect(() => {
        generateAdGroupsMap();
        if (props.dealerId) {
            fetchAdCreatives(props.dealerId);
        }
    }, []);

    const renderVideoPreview = (url: string, contentType: string) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    overflow: 'hidden',
                }}
            >
                <video
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }}
                    controls
                >
                    <source src={url} type={contentType} />
                    Your browser does not support the video tag.
                </video>
            </Box>
        );
    };

    const renderImagePreview = (url: string, width: number, height: number) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: width,
                    height: height,
                    overflow: 'hidden',
                }}
            >
                <img
                    src={url}
                    alt="Creative asset"
                    style={{
                        width: width,
                        height: height,
                    }}
                />
            </Box>
        );
    };

    const renderHTMLPreview = (html: string) => {
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
    };

    const renderItem = (adCreative: AdCreative, index: number) => {
        return (
            <Item key={index} sx={{ display: 'block' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <div style={{ fontWeight: 'bold' }}>{adCreative.name}</div>|
                    <div style={{ fontWeight: 'bold' }}>
                        {adCreative.assets[0].fileMetadataObject.contentType === 'video/mp4' &&
                            `${adCreative.assets[0].fileMetadataObject.resolutionWidth}x${adCreative.assets[0].fileMetadataObject.resolutionHeight}`}
                        {adCreative.assets[0].fileMetadataObject.contentType.startsWith('image') &&
                            `${adCreative.assets[0].fileMetadataObject.width}x${adCreative.assets[0].fileMetadataObject.height}`}
                    </div>
                </Box>
                {adCreative.assets[0].fileMetadataObject.contentType === 'video/mp4'
                    ? renderVideoPreview(adCreative.assets[0].url, adCreative.assets[0].fileMetadataObject.contentType)
                    : renderImagePreview(
                          adCreative.assets[0].url,
                          adCreative.assets[0].fileMetadataObject.width,
                          adCreative.assets[0].fileMetadataObject.height
                      )}
                {adCreative.assets[0].fileMetadataObject.contentType === 'HTML' &&
                    renderHTMLPreview(adCreative.assets[0].fileMetadata)}
            </Item>
        );
    };

    function distictOnly(value: any, index: any, array: any[]) {
        return array.indexOf(value) === index;
    }

    const renderCreatives = () => {
        const creativeList: JSX.Element[] = [];
        const allCreatives: any[] = [];
        if (adGroup && adGroup.name === 'All') {
            const allAdGroups = adGroupsByDate.get(selectedMonthYear);
            allAdGroups?.forEach((_adGroup) => {
                if (_adGroup?.dspAdGroupId) {
                    const creatives = adCreativeMap.get(_adGroup?.dspAdGroupId);
                    if (creatives) {
                        allCreatives.push(...creatives);
                    }
                }
            });
        }

        if (adGroup?.dspAdGroupId) {
            const creatives = adCreativeMap.get(adGroup?.dspAdGroupId);
            if (creatives) {
                allCreatives.push(...creatives);
            }
        }

        const distinctCreatives = allCreatives.filter(distictOnly);
        distinctCreatives?.sort(
            (a, b) => a.assets[0].fileMetadataObject.height - b.assets[0].fileMetadataObject.height
        );
        distinctCreatives?.map((adCreative, index) => {
            if (adCreative.assets && adCreative.assets.length > 0) {
                creativeList.push(renderItem(adCreative, index));
            }
        });

        if (!creativeList.length) {
            creativeList.push(
                <Alert severity="info">No creatives found for the Campaign Month or Ad Group chosen</Alert>
            );
        }
        return creativeList;
    };

    const handleChange = (newDate: Dayjs | null): void => {
        if (newDate) {
            const month = newDate.toDate().getMonth() + 1;
            const year = newDate.toDate().getFullYear();
            const allAdGroup: CampaignAdGroup = {
                id: 0,
                name: 'All',
            };
            const monthYear = `${year}-${month}`;
            let adGroupList = adGroupsByDate.get(monthYear);
            adGroupList?.push(allAdGroup);
            if (!adGroupList) {
                adGroupList = [];
            }
            adGroupList.sort((a, b) => {
                if (a.name && b.name) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                }
                return 1;
            });
            setSelectedDate(newDate);
            setSelectedMonthYear(monthYear);
            setAdGroups(adGroupList);
            setAdGroup(allAdGroup);
        }
    };

    return (
        <Box>
            {props.campaign?.mediaType?.toLowerCase() === 'audio' && (
                <Alert severity="warning">Audio creative previews are not supported at this time</Alert>
            )}
            {props.campaign?.mediaType?.toLowerCase() !== 'audio' && (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CDMonthDatePicker
                            label="Campaign Month"
                            value={selectedDate}
                            onChange={handleChange}
                            sx={{ width: 200 }}
                        />

                        <Autocomplete
                            sx={{
                                width: '400px',
                                marginLeft: '10px',
                            }}
                            value={adGroup}
                            size="small"
                            options={adGroups}
                            id="select-adGroup"
                            // disabled={enabled}
                            getOptionLabel={(option) => option?.name || ''}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            onChange={(e, value) => {
                                setAdGroup(value);
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Ad Group" />}
                        />
                    </Box>
                    <Box sx={{ m: 5 }} />
                    <Masonry columns={1} spacing={2} defaultSpacing={1}>
                        {renderCreatives()}
                    </Masonry>
                </>
            )}
        </Box>
    );
}
