import { isValidElement, useContext } from 'react';
import { Box, Button, Card, Divider, CardContent, CardHeader, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { InfoMessageContext } from '../../../components/GlobalInfoMessage';
import Column from '../../../components/Column';
import Row from '../../../components/Row';

class CardData {
    title: string | JSX.Element = '';
    mainContent: string = '';
    description: string | null | undefined | React.ReactElement = '';
}

const ContentCard = (props: { cardData: CardData }) => {
    const { cardData } = props;
    const { showMessage } = useContext(InfoMessageContext);

    const handleCopy = (): void => {
        navigator.clipboard.writeText(cardData.mainContent);
        showMessage('success', 'Copied to clipboard!');
    };

    return (
        <Card variant="outlined">
            <CardHeader title={cardData.title} titleTypographyProps={{ variant: 'body2', fontWeight: 500 }} />

            <Divider />

            <CardContent>
                <Column gap={3}>
                    {isValidElement(cardData.description) ? (
                        cardData.description
                    ) : (
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'text.secondary',
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                            }}
                        >
                            {cardData.description}
                        </Typography>
                    )}

                    <Box
                        sx={{
                            color: 'text.primary',
                            padding: (theme) => theme.spacing(2),
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: (theme) => theme.palette.divider,
                            backgroundColor: grey[50],
                            borderRadius: (theme) => `${theme.shape.borderRadius / 2}px`,
                        }}
                    >
                        <Row justifyContent="space-between">
                            <Box
                                sx={{
                                    width: 0.75,
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    wordBreak: 'break-word',
                                    overflow: 'hidden',
                                }}
                            >
                                <Typography component="pre" variant="body2" color="text.secondary">
                                    {cardData.mainContent}
                                </Typography>
                            </Box>

                            <Box flex={0}>
                                <Tooltip title="Copy to clipboard">
                                    <Button variant="contained" size="medium" color="primary" onClick={handleCopy}>
                                        Copy
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Row>
                    </Box>
                </Column>
            </CardContent>
        </Card>
    );
};

export type { CardData };
export default ContentCard;
