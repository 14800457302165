import { useMemo } from 'react';
import { Chip, ChipProps, CircularProgress } from '@mui/material';
import { TrackingStatus, Tracking } from '../../../../types/HAT/Tracking';

interface TrackingStatusChipProps {
    tracking: Tracking | null;
}

const TrackingStatusChip = (props: TrackingStatusChipProps) => {
    const { tracking } = props;

    let status: string = 'Not Tracking';
    let color: ChipProps['color'] = 'default';

    if (tracking) {
        switch (tracking.status) {
            case TrackingStatus.NEW:
                status = 'Pending Tracking';
                color = 'warning';
                break;
            case TrackingStatus.ALIVE:
                status = 'Tracking';
                color = 'success';
                break;
            case TrackingStatus.DEAD:
                status = 'Stopped Tracking';
                color = 'error';
                break;
        }
    }

    const isLoading = useMemo((): boolean => {
        return tracking === null;
    }, [tracking]);

    return (
        <Chip
            label={isLoading ? 'Fetching Tracking Status' : status}
            size="small"
            color={color}
            icon={isLoading ? <CircularProgress size={16} thickness={6} /> : <></>}
        />
    );
};

export default TrackingStatusChip;
